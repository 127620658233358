import React, { useEffect, useState } from 'react';
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../firebase';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Paper, Typography, Container, TextField, Box, Button, Dialog, DialogContent, DialogTitle, Card, CardContent, Grid
} from '@mui/material';
import { formatDate } from '../utils/utils';
import './MovingOutList.css';
import LicenseeForm from './LicenseeForm';  // Import LicenseeForm

function MovingOutList() {
  const [notices, setNotices] = useState([]);
  const [search, setSearch] = useState('');
  const [filteredNotices, setFilteredNotices] = useState([]);
  const [sortOrder, setSortOrder] = useState('asc');
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [emptyBeds, setEmptyBeds] = useState([]); // Empty beds state
  const [showForm, setShowForm] = useState(false);
  const [selectedReferenceCode, setSelectedReferenceCode] = useState(''); // Selected reference code for LicenseeForm
  const [preSelectedMoveInDate, setPreSelectedMoveInDate] = useState(''); // Move-in date state
  const [selectedNotice, setSelectedNotice] = useState(null); // For viewing detailed notice in mobile

  useEffect(() => {
    const fetchNotices = async () => {
      const noticesSnapshot = await getDocs(collection(db, 'notices'));
      const noticesList = noticesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setNotices(noticesList);
    };

    fetchNotices();
  }, []);

  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const filteredList = notices.filter(notice => 
      notice.name.toLowerCase().includes(search.toLowerCase()) ||
      notice.referenceCode.toLowerCase().includes(search.toLowerCase()) ||
      notice.reason.toLowerCase().includes(search.toLowerCase())
    );

    filteredList.sort((a, b) => {
      const dateA = new Date(a.noticeDate);
      const dateB = new Date(b.noticeDate);
      return sortOrder === 'asc' ? dateA - dateB : dateB - dateA;
    });

    setFilteredNotices(filteredList);
  }, [search, notices, sortOrder]);

  useEffect(() => {
    const fetchEmptyBeds = async () => {
      const emptyBedsList = [];

      for (const notice of notices) {
        if (!notice.referenceCode) {
          continue;  // Skip notices with undefined or empty referenceCode
        }

        const q = query(
          collection(db, 'licensees'),
          where('REFERENCE CODE', '==', notice.referenceCode)
        );

        const licenseesSnapshot = await getDocs(q);

        if (licenseesSnapshot.empty) {
          emptyBedsList.push({
            name: notice.name,
            referenceCode: notice.referenceCode,
            noticeDate: notice.noticeDate,
          });
        }
      }

      setEmptyBeds(emptyBedsList);
    };

    if (notices.length > 0) {
      fetchEmptyBeds();
    }
  }, [notices]);

  const toggleSortOrder = () => {
    setSortOrder(prevOrder => (prevOrder === 'asc' ? 'desc' : 'asc'));
  };

  const handleOpenForm = (referenceCode, moveOutDate) => {
    setSelectedReferenceCode(referenceCode || '');  // Set selected reference code
    setPreSelectedMoveInDate(moveOutDate);  // Set the move-out date as the pre-selected move-in date
    setShowForm(true);  // Show LicenseeForm with selected reference code
  };

  const handleCloseForm = () => {
    setShowForm(false);  // Close the form dialog
  };

  const handleViewNotice = (notice) => {
    setSelectedNotice(notice); // Set the selected notice to view its details
  };

  const handleCloseView = () => {
    setSelectedNotice(null); // Close the view notice dialog
  };

  return (
    <Container>
      <Typography variant="h4" gutterBottom>
        Moving Out List
      </Typography>
      <TextField
        label="Search"
        variant="outlined"
        fullWidth
        margin="normal"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
      <Button variant="contained" onClick={toggleSortOrder}>
        Sort by Move-Out Date ({sortOrder === 'asc' ? 'Ascending' : 'Descending'})
      </Button>

      {isMobile ? (
        // Mobile view: Only show Reference Code with View and Fill buttons
        <Box mt={4}>
          <Typography variant="h5" gutterBottom>
            Upcoming Empty Beds (Mobile View)
          </Typography>
          {filteredNotices.map((notice) => (
            <Card key={notice.id} sx={{ marginBottom: '16px' }}>
              <CardContent>
                <Typography variant="h6">
                  Reference Code: {notice.referenceCode}
                </Typography>
                <Box display="flex" justifyContent="space-between" mt={2}>
                  <Button variant="contained" onClick={() => handleViewNotice(notice)}>
                    View
                  </Button>
                  <Button variant="contained" color="primary" onClick={() => handleOpenForm(notice.referenceCode, notice.noticeDate)}>
                    Fill
                  </Button>
                </Box>
              </CardContent>
            </Card>
          ))}
        </Box>
      ) : (
        // Desktop view: Show the full table
        <Box mt={4}>
          <Typography variant="h5" gutterBottom>
            Upcoming Empty Beds
          </Typography>
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell>Reference Code</TableCell>
                  <TableCell>Move-Out Date</TableCell>
                  <TableCell>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {filteredNotices.map((notice) => (
                  <TableRow key={notice.id}>
                    <TableCell>{notice.name}</TableCell>
                    <TableCell>{notice.referenceCode}</TableCell>
                    <TableCell>{formatDate(notice.noticeDate)}</TableCell>
                    <TableCell>
                      <Button variant="contained" onClick={() => handleOpenForm(notice.referenceCode, notice.noticeDate)}>
                        Fill Bed
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      )}

      {/* Render LicenseeForm in a dialog (modal) */}
      <Dialog open={showForm} onClose={handleCloseForm}>
        <DialogTitle>Fill Bed</DialogTitle>
        <DialogContent>
          <LicenseeForm preSelectedReferenceCode={selectedReferenceCode} preSelectedMoveInDate={preSelectedMoveInDate} />
        </DialogContent>
      </Dialog>

      {/* Render View Notice dialog in mobile */}
      {selectedNotice && (
        <Dialog open={Boolean(selectedNotice)} onClose={handleCloseView}>
          <DialogTitle>Notice Details</DialogTitle>
          <DialogContent>
            <Typography variant="body1">
              <strong>Name:</strong> {selectedNotice.name}
            </Typography>
            <Typography variant="body1">
              <strong>Reference Code:</strong> {selectedNotice.referenceCode}
            </Typography>
            <Typography variant="body1">
              <strong>Move-Out Date:</strong> {formatDate(selectedNotice.noticeDate)}
            </Typography>
            <Typography variant="body1">
              <strong>Reason:</strong> {selectedNotice.reason}
            </Typography>
          </DialogContent>
        </Dialog>
      )}
    </Container>
  );
}

export default MovingOutList;
