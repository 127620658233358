import React from 'react';
import { Container, Typography, Box, Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const ReferenceSuccessScreen = () => {
  const navigate = useNavigate();

  return (
    <Container maxWidth="sm">
      <Box sx={{ textAlign: 'center', mt: 5 }}>
        <Typography variant="h4" gutterBottom>
          Application Submitted Successfully!
        </Typography>
        <Typography variant="body1" sx={{ mt: 2, mb: 4 }}>
          Thank you for submitting your references. We will review your application and contact you soon.
        </Typography>
        <Button variant="contained" color="primary" onClick={() => navigate('/')}>
          Go to Home
        </Button>
      </Box>
    </Container>
  );
};

export default ReferenceSuccessScreen;
