import React, { useState, useEffect } from 'react';
import { Container, Typography, Box, Button, Paper, Alert, TextField } from '@mui/material';
import { generateContract, generateProofOfAddress } from '../utils/docxUtils'; // DOCX-based functions
import { useAuth } from '../contexts/AuthContext';
import { doc, getDoc, collection, query, where, getDocs } from 'firebase/firestore'; // Firestore functions
import { db, storage } from '../firebase';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import axios from 'axios';
import { getAuth } from 'firebase/auth'; // Import for Firebase Auth

function formatDate(dateString) {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed in JavaScript
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
}

function capitalizeWords(str) {
  return str
    .split(' ')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
}

// Helper function to get Firebase Auth token
async function getAuthToken() {
  const auth = getAuth();
  return await auth.currentUser.getIdToken(); // Get the ID token for the current user
}

function GetDocuments() {
  const { currentUser } = useAuth();
  const [formData, setFormData] = useState({
    licenseeName: '',
    propertyAddress: '',
    room: '',
    referenceCode: '',
    bankDetails: '',
    rentAmount: '',
    depositAmount: '',
    startDate: '',
    endDate: '',
    emailAddress: '',
    passportNumber: '', // Optional field for the Proof of Address
    term: '6 MONTHS',
  });
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchUserData = async () => {
      if (currentUser) {
        try {
          const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
          if (userDoc.exists()) {
            const userData = userDoc.data();

            const licenseeQuery = query(
              collection(db, 'licensees'),
              where('REFERENCE CODE', '==', userData.referenceCode)
            );
            const licenseeSnapshot = await getDocs(licenseeQuery);

            if (!licenseeSnapshot.empty) {
              const licenseeData = licenseeSnapshot.docs[0].data();
              setFormData({
                ...formData,
                licenseeName: capitalizeWords(userData.fullName),
                propertyAddress: capitalizeWords(licenseeData.ADDRESS),
                room: licenseeData.BEDROOM,
                referenceCode: userData.referenceCode,
                rentAmount: licenseeData.RENT,
                depositAmount: licenseeData.DEPOSIT,
                startDate: formatDate(licenseeData['MOVED IN']),
                endDate: formatDate(licenseeData['MOVING OUT']),
                emailAddress: userData.email,
              });
            } else {
              setError('No matching licensee found for the reference code.');
            }
          } else {
            setError('No user data found!');
          }
        } catch (err) {
          console.error('Error fetching data:', err);
          setError('Failed to fetch data: ' + err.message);
        }
      }
    };

    fetchUserData();
  }, [currentUser]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleGenerateContract = async () => {
    setMessage('Generating, please wait...');
    setError('');
    setLoading(true);

    try {
      const docxBlob = await generateContract(formData);
      const storageRef = ref(storage, `contracts/${Date.now()}-contract.docx`);
      const uploadTask = uploadBytesResumable(storageRef, docxBlob, {
        contentType: 'docx',
      });

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log('Upload is ' + progress + '% done');
        },
        (error) => {
          console.error('Error uploading DOCX:', error);
          setError('Failed to upload DOCX: ' + error.message);
          setMessage('');
          setLoading(false);
        },
        async () => {
          try {
            const docxUrl = await getDownloadURL(uploadTask.snapshot.ref);
            const token = await getAuthToken(); // Get the Firebase token

            const functionUrl = 'https://europe-west1-lobi-portal.cloudfunctions.net/convertDocxToPdf';
            let conversionResponse;
            let attempts = 0;
            const maxAttempts = 5;

            while (attempts < maxAttempts) {
              try {
                conversionResponse = await axios.post(functionUrl, { docxUrl }, {
                  headers: { Authorization: `Bearer ${token}` }, // Include the token in the request
                });
                break;
              } catch (error) {
                if (error.response && error.response.status === 429) {
                  attempts += 1;
                  await new Promise(resolve => setTimeout(resolve, 1000 * attempts));
                } else {
                  throw error;
                }
              }
            }

            if (!conversionResponse) {
              throw new Error("Failed to convert DOCX to PDF after multiple attempts");
            }

            const pdfUrl = conversionResponse.data.pdfUrl;
            setMessage('Contract generated successfully!');

            // Call eversign API to send document for signature
            await sendDocumentForSignature(pdfUrl, formData.licenseeName, formData.emailAddress);

            setLoading(false);
          } catch (conversionError) {
            console.error('Error during PDF conversion:', conversionError);
            setError('Failed to convert DOCX to PDF: ' + conversionError.message);
            setMessage('');
            setLoading(false);
          }
        }
      );
    } catch (err) {
      console.error('Failed to generate contract:', err.message);
      setError('Failed to generate contract: ' + err.message);
      setMessage('');
      setLoading(false);
    }
  };

  const handleGenerateProofOfAddress = async () => {
    setMessage('Generating, please wait...');
    setError('');
    setLoading(true);

    try {
      const docxBlob = await generateProofOfAddress(formData);
      const storageRef = ref(storage, `proofs/${Date.now()}-proof_of_address.docx`);
      const uploadTask = uploadBytesResumable(storageRef, docxBlob, {
        contentType: 'docx',
      });

      uploadTask.on(
        'state_changed',
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log('Upload is ' + progress + '% done');
        },
        (error) => {
          console.error('Error uploading DOCX:', error);
          setError('Failed to upload DOCX: ' + error.message);
          setMessage('');
          setLoading(false);
        },
        async () => {
          try {
            const docxUrl = await getDownloadURL(uploadTask.snapshot.ref);
            const token = await getAuthToken(); // Get the Firebase token

            const functionUrl = 'https://europe-west1-lobi-portal.cloudfunctions.net/convertDocxToPdf';
            let conversionResponse;
            let attempts = 0;
            const maxAttempts = 5;

            while (attempts < maxAttempts) {
              try {
                conversionResponse = await axios.post(functionUrl, { docxUrl }, {
                  headers: { Authorization: `Bearer ${token}` }, // Include the token in the request
                });
                break;
              } catch (error) {
                if (error.response && error.response.status === 429) {
                  attempts += 1;
                  await new Promise(resolve => setTimeout(resolve, 1000 * attempts));
                } else {
                  throw error;
                }
              }
            }

            if (!conversionResponse) {
              throw new Error("Failed to convert DOCX to PDF after multiple attempts");
            }

            const pdfUrl = conversionResponse.data.pdfUrl;
            setMessage('Proof of Address generated successfully! Click the link to download.');

            const link = document.createElement('a');
            link.href = pdfUrl;
            link.setAttribute('download', 'proof_of_address.pdf');
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            setLoading(false);
          } catch (conversionError) {
            console.error('Error during PDF conversion:', conversionError);
            setError('Failed to convert DOCX to PDF: ' + conversionError.message);
            setMessage('');
            setLoading(false);
          }
        }
      );
    } catch (err) {
      console.error('Failed to generate Proof of Address:', err.message);
      setError('Failed to generate Proof of Address: ' + err.message);
      setMessage('');
      setLoading(false);
    }
  };

  const fetchApiKeys = async () => {
    try {
      const response = await axios.get('https://us-central1-lobi-portal.cloudfunctions.net/getApiKeys');
      return response.data;
    } catch (error) {
      console.error('Error fetching API keys:', error);
    }
  };

  const sendDocumentForSignature = async (pdfUrl, name, email) => {
    try {
      const keys = await fetchApiKeys();
      const accessKey = keys.eversignAccessKey; 
      const businessId = keys.eversignBusinessId; 
      const response = await axios.post(
        `https://api.eversign.com/api/document?access_key=${accessKey}&business_id=${businessId}`, 
        {
          title: 'Contract for Signature',
          files: [{
            name: 'Contract Document',
            file_url: pdfUrl,
          }],
          signers: [{
            id: 1,
            name: name,  // Name from formData
            email: email, // Email from formData
            order: 1,
          }],
          requester_email: "references@lobi.ie",
          custom_requester_name: "Linehan O'Brien Investments Ltd",
	        custom_requester_email: "kevin@lobi.ie",
          sandbox: 0,
        },
        {
          headers: {
            'Content-Type': 'application/json'
          }
        }
      );



      setMessage('Document sent for signature via email.');

    } catch (err) {
      console.error('Error sending document for signature:', err.response ? err.response.data : err.message);
      setError('Failed to send document for signature.');
    }
  };

  return (
    <Container maxWidth="md">
      <Paper elevation={3} sx={{ padding: 4, backgroundColor: 'background.paper' }}>
        <Typography variant="h4" gutterBottom>
          Get Your Documents
        </Typography>

        {error && <Alert severity="error">{error}</Alert>}
        {message && <Alert severity="success">{message}</Alert>}

        <Box sx={{ mt: 3 }}>
          <TextField
            label="Passport Number (Optional)"
            name="passportNumber"
            value={formData.passportNumber}
            onChange={handleInputChange}
            fullWidth
            margin="normal"
          />
        </Box>

        <Box sx={{ mt: 2 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleGenerateProofOfAddress}
            disabled={loading}
            sx={{ minWidth: 200, m: 1 }}
          >
            Generate Proof of Address
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleGenerateContract}
            disabled={loading}
            sx={{ minWidth: 200, m: 1 }}
          >
            Generate Contract
          </Button>
        </Box>
      </Paper>
    </Container>
  );
}

export default GetDocuments;
