// HouseRules.js
import React from 'react';
import { Container, Typography, Box, Paper } from '@mui/material';

function HouseRules() {
  return (
    <Container maxWidth="md">
      <Paper elevation={3} sx={{ p: 3 }}>
        <Box sx={{ mt: 4, mb: 4 }}>
          <Typography variant="h4" component="h1" gutterBottom>
            House Rules
          </Typography>

          <Typography variant="body1" sx={{ mb: 2 }}>
            1 - Parties and any noise that annoys your flatmates and neighbours are not allowed and after 10pm noise levels should cease. In case of any meeting, once finished, make sure the place is clean immediately.
          </Typography>

          <Typography variant="body1" sx={{ mb: 2 }}>
            2 - It is strictly forbidden to smoke inside the house. If we detect a Licensee smoking inside, we can notify that person to move out within 28 days.
          </Typography>

          <Typography variant="body1" sx={{ mb: 2 }}>
            3 - The house must be cleaned every week, so you and your flatmates must have a weekly cleaning routine. You must send pictures of the cleaned areas to the house group as proof.
          </Typography>

          <Typography variant="body1" sx={{ mb: 2 }}>
            4 - Pets are not allowed.
          </Typography>

          <Typography variant="body1" sx={{ mb: 2 }}>
            5 - Kitchen rules: The kitchen must be clean and organized at all times. After using the kitchen, all dishes must be washed, dried, and stored. Also, clean the dining table and counters used for cooking/eating. Don't leave the kitchen until all tasks are complete.
          </Typography>

          <Typography variant="body1" sx={{ mb: 2 }}>
            6 - Maintenance rules and problem reports: Any issue in the house must be reported immediately to the house WhatsApp group via photos, videos, and a written explanation.
          </Typography>

          <Typography variant="body1" sx={{ mb: 2 }}>
            7 - Moving rules (CHECK IN): The minimum stay is 3 months. If you need to move out early due to an emergency, contact us for authorization to find a replacement. Do not arrange for someone to take your spot without landlord approval.
          </Typography>

          <Typography variant="body1" sx={{ mb: 2 }}>
            7.2 - Moving rules: After 2 months of residence, you may notify the landlord 28 days before your departure. The deposit will be refunded after check-out.
          </Typography>

          <Typography variant="body1" sx={{ mb: 2 }}>
            7.3 - Moving rules (CHECK OUT): A video of the bedroom and common spaces must be sent for checkout. Also include photos of the kitchen cabinet, fridge spaces, and where you leave the key.
          </Typography>
        </Box>
      </Paper>
    </Container>
  );
}

export default HouseRules;
