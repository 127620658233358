import React, { useState, useEffect } from 'react';
import { TextField, MenuItem, Typography } from '@mui/material';



// Comprehensive Categories and Subcategories structure
const categories = {
  "Bathroom and Toilet": {
    "Electric Shower": ["No hot water", "Not working at all", "Other"],
    "Tap / Basin": ["Tap broken", "Tap dripping", "Pipe leaking", "Tap loose", "Other"],
    "Toilet seat/bowl": ["Toilet bowl cracked", "Toilet seat broken", "Other"],
    "Pipes": ["Pipe leaking", "Waste pipe blocked", "Other"],
    "Plug hole": ["Plug hole blocked", "Other"],
    "Shower": ["Not working", "Leaking", "Other"],
    "Extractor Fan": ["Not working", "Other"],
    "Between bath and wall": ["Sealant discoloured/mouldy", "Water leaking between bath and wall", "Other"],
  },
  "WC/Toilet": {
    "Cistern": ["Cistern constantly flowing", "Cistern not filling with water", "Cistern not flushing", "Cistern overflowing", "Cracked cistern", "Major leak that cannot be stopped", "Other"],
    "Handle/chain": ["Chain broken", "Handle broken", "Other"],
    "Toilet seat/bowl": ["Toilet bowl cracked", "Toilet seat broken", "Toilet seat loose", "Other"],
    "Pipes": ["Flush pipe blocked", "Flush pipe leaking", "Toilet blocked", "Waste pipe leaking", "Other"],
  },
  "Kitchen": {
    "Sink": ["Blocked", "Leaking", "Cracked", "Other"],
    "Tap": ["Dripping", "Broken", "Loose", "Other"],
    "Cooker": ["Not working", "Gas smell", "Other"],
    "Oven": ["Not heating", "Door broken", "Other"],
    "Fridge": ["Not cooling", "Noisy", "Other"],
    "Freezer": ["Not freezing", "Ice build-up", "Other"],
    "Dishwasher": ["Not working", "Leaking", "Other"],
    "Extractor Fan": ["Not working", "Noisy", "Other"],
    "Worktops": ["Damaged", "Loose", "Other"],
    "Cupboards": ["Broken hinges", "Loose doors", "Other"],
  },
  "Heating and Boiler": {
    "Boiler": ["No hot water", "No heating", "Leaking", "Error code", "Other"],
    "Radiator": ["Not heating up", "Leaking", "Noisy", "Other"],
    "Thermostat": ["Not working", "Faulty reading", "Other"],
  },
  "Water and Leaks": {
    "Leaks": ["Ceiling leaking", "Wall damp", "Floor wet", "Other"],
    "Pipes": ["Burst pipe", "Leaking pipe", "No water supply", "Other"],
    "Water Pressure": ["Low pressure", "High pressure", "Intermittent supply", "Other"],
  },
  "Doors, Garages, and Locks": {
    "Front Door": ["Lock broken", "Door not closing", "Damaged", "Other"],
    "Internal Door": ["Handle broken", "Won't close", "Damaged", "Other"],
    "Garage Door": ["Won't open", "Remote not working", "Damaged", "Other"],
    "Window Locks": ["Not locking", "Key missing", "Other"],
  },
  "Internal floors, walls, and ceilings": {
    "Walls": ["Cracks", "Damp", "Paint peeling", "Other"],
    "Ceilings": ["Cracks", "Damp", "Leakage", "Other"],
    "Floors": ["Loose floorboards", "Damaged tiles", "Carpet worn", "Other"],
  },
  "Lighting": {
    "Light Fixture": ["Not working", "Flickering", "Broken", "Other"],
    "Switch": ["Not working", "Sparking", "Loose", "Other"],
    "Bulb": ["Needs replacement", "Can't reach", "Other"],
  },
  "Window": {
    "Glass": ["Cracked", "Broken", "Condensation", "Other"],
    "Frame": ["Damaged", "Rotten", "Loose", "Other"],
    "Handle": ["Broken", "Loose", "Other"],
    "Seals": ["Draft", "Water ingress", "Other"],
  },
  "Exterior and Garden": {
    "Fence": ["Broken", "Missing panels", "Other"],
    "Gate": ["Broken lock", "Not closing", "Other"],
    "Path": ["Uneven", "Slippery", "Other"],
    "Garden": ["Overgrown", "Tree fallen", "Other"],
    "Roof": ["Tiles missing", "Leak", "Other"],
  },
  "Laundry": {
    "Washing Machine": ["Not working", "Leaking", "Noisy", "Other"],
    "Dryer": ["Not working", "No heat", "Noisy", "Other"],
    "Clothes Line": ["Broken", "Missing", "Other"],
  },
  "Furniture": {
    "Bed": ["Broken", "Squeaky", "Missing parts", "Other"],
    "Sofa": ["Damaged", "Stains", "Other"],
    "Table": ["Wobbly", "Broken", "Other"],
    "Chair": ["Broken", "Wobbly", "Other"],
  },
  "Electricity": {
    "Power Outage": ["No power in property", "Partial outage", "Other"],
    "Socket": ["Not working", "Sparking", "Loose", "Other"],
    "Circuit Breaker": ["Tripping", "Won't reset", "Other"],
  },
  "Hot Water": {
    "No Hot Water": ["From taps", "From shower", "Intermittent", "Other"],
    "Hot Water Tank": ["Leaking", "Not heating", "Other"],
  },
  "Alarms and Smoke Detectors": {
    "Smoke Alarm": ["Beeping", "Not working", "Other"],
    "Carbon Monoxide Alarm": ["Beeping", "Not working", "Other"],
    "Security Alarm": ["False alarms", "Not arming", "Other"],
  },
  "Roof": {
    "Leak": ["In attic", "Through ceiling", "Other"],
    "Tiles": ["Missing", "Broken", "Other"],
    "Gutters": ["Blocked", "Leaking", "Other"],
  },
  "Utility Meters": {
    "Electricity Meter": ["Not working", "Reading incorrect", "Other"],
    "Gas Meter": ["Not working", "Smell gas", "Other"],
    "Water Meter": ["Not working", "Leak", "Other"],
  },
  "Smell Gas?": {
    "Gas Leak": ["In kitchen", "Near boiler", "Outside property", "Other"],
  },
  "Fire": {
    "Fire Incident": ["In kitchen", "Electrical fire", "Other"],
  },
  "Other": {
    "Other Issues": ["Describe the issue in detail"],
  },
};



const SubCategorySelection = ({ defaultCategory, onChange }) => {
  const [category, setCategory] = useState(defaultCategory || "");
  const [subcategory, setSubcategory] = useState("");
  const [issue, setIssue] = useState("");
  const [customIssue, setCustomIssue] = useState("");
  const [description, setDescription] = useState("");

  useEffect(() => {
    if (defaultCategory) {
      setCategory(defaultCategory);
    }
  }, [defaultCategory]);

  const handleCategoryChange = (e) => {
    const newCategory = e.target.value;
    setCategory(newCategory);
    setSubcategory("");
    setIssue("");
    setCustomIssue("");
    setDescription(""); // Reset description when category changes
    onChange && onChange({ category: newCategory, subcategory: "", issue: "", customIssue: "", description: "" });
  };
  
  const handleSubcategoryChange = (e) => {
    const newSubcategory = e.target.value;
    setSubcategory(newSubcategory);
    setIssue("");
    setCustomIssue("");
    setDescription(""); // Reset description when subcategory changes
    onChange && onChange({ category, subcategory: newSubcategory, issue: "", customIssue: "", description: "" });
  };
  
  const handleIssueChange = (e) => {
    const newIssue = e.target.value;
    setIssue(newIssue);
    if (newIssue !== "Other") {
      setCustomIssue("");
    }
    onChange && onChange({ category, subcategory, issue: newIssue, customIssue, description });
  };
  
  const handleCustomIssueChange = (e) => {
    setCustomIssue(e.target.value);
    onChange && onChange({ category, subcategory, issue, customIssue: e.target.value, description });
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
    onChange && onChange({ category, subcategory, issue, customIssue, description: e.target.value });
  };
  
  

  return (
    <div>
      <Typography variant="h6" sx={{ mt: 2 }}>
        What is the problem?
      </Typography>

      {/* Category Dropdown */}
      {!defaultCategory && (
        <TextField
          select
          label="Category"
          value={category}
          onChange={handleCategoryChange}
          fullWidth
          margin="normal"
        >
          {Object.keys(categories).map((categoryKey) => (
            <MenuItem key={categoryKey} value={categoryKey}>
              {categoryKey}
            </MenuItem>
          ))}
        </TextField>
      )}

      {/* Subcategory Dropdown */}
      {category && (
        <TextField
          select
          label="Subcategory"
          value={subcategory}
          onChange={handleSubcategoryChange}
          fullWidth
          margin="normal"
        >
          {Object.keys(categories[category]).map((subCategoryKey) => (
            <MenuItem key={subCategoryKey} value={subCategoryKey}>
              {subCategoryKey}
            </MenuItem>
          ))}
        </TextField>
      )}

      {/* Issue Dropdown */}
      {subcategory && (
        <TextField
          select
          label="Issue"
          value={issue}
          onChange={handleIssueChange}
          fullWidth
          margin="normal"
        >
          {categories[category][subcategory].map((issueItem, index) => (
            <MenuItem key={index} value={issueItem}>
              {issueItem}
            </MenuItem>
          ))}
        </TextField>
      )}

      <TextField
        label="Description, and please tell us how serious it is"
        value={description}
        onChange={handleDescriptionChange}
        fullWidth
        multiline
        rows={4}
        margin="normal"
        required
      />


    </div>
  );
};

export default SubCategorySelection;
