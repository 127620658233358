import React, { useEffect, useState } from 'react';
import { db } from '../firebase';
import { collection, getDocs, query, orderBy, doc, deleteDoc } from 'firebase/firestore';
import {
  Container, Typography, Box, CircularProgress, Paper, Button, TextField, Dialog, DialogActions, DialogContent,
  DialogTitle, FormControl, InputLabel, Select, MenuItem, IconButton, useMediaQuery
} from '@mui/material';
import styled from 'styled-components';
import SearchIcon from '@mui/icons-material/Search';
import { formatDate } from '../utils/utils';
import { FixedSizeList as List } from 'react-window';

const ScrollableContainer = styled(Paper)`
  overflow: hidden;
  height: 600px;
  width: 100%;
  max-width: 100%;
`;

const RowGrid = styled.div`
  display: grid;
  grid-template-columns: 120px 120px 120px 80px 80px 120px 80px 80px 80px 80px;
  padding: 8px;
  border-bottom: 1px solid #ddd;
  align-items: center;
  font-size: 14px;
`;

const MobileRowGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 8px;
  border-bottom: 1px solid #ddd;
  align-items: center;
  font-size: 14px;
`;

const TextCell = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 5px 8px;
`;

const ButtonCell = styled.div`
  display: flex;
  justify-content: center;
  padding: 5px;
`;

const SmallButton = styled(Button)`
  font-size: 10px;
  padding: 4px 6px;
`;

const LicenseeList = () => {
  const [licensees, setLicensees] = useState([]);
  const [filteredLicensees, setFilteredLicensees] = useState([]);
  const [notices, setNotices] = useState([]);
  const [propertyCodes, setPropertyCodes] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedPropertyCode, setSelectedPropertyCode] = useState('');
  const [filterMovingOut, setFilterMovingOut] = useState(false);
  const [detailsOpen, setDetailsOpen] = useState(false);
  const [selectedLicensee, setSelectedLicensee] = useState(null);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [licenseeToDelete, setLicenseeToDelete] = useState(null);

  const isMobile = useMediaQuery('(max-width:600px)'); // Detect mobile screens

  const fetchAllLicensees = async () => {
    setLoading(true);
    const licenseeCollection = collection(db, 'licensees');
    const licenseeSnapshot = await getDocs(query(licenseeCollection, orderBy('REFERENCE CODE')));
    const allLicensees = licenseeSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

    setLicensees(allLicensees);
    setFilteredLicensees(allLicensees);

    const propertyCodesSet = new Set(allLicensees.map(licensee => extractPropertyCode(licensee['REFERENCE CODE'])));
    setPropertyCodes(Array.from(propertyCodesSet));

    const noticesCollection = collection(db, 'notices');
    const noticesSnapshot = await getDocs(noticesCollection);
    const noticesList = noticesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setNotices(noticesList);

    setLoading(false);
  };

  useEffect(() => {
    fetchAllLicensees();
  }, []);

  useEffect(() => {
    const lowerCaseSearchTerm = searchTerm.toLowerCase();
    let filtered = licensees;

    if (selectedPropertyCode) {
      filtered = filtered.filter(licensee =>
        extractPropertyCode(licensee['REFERENCE CODE']) === selectedPropertyCode
      );
    }

    if (searchTerm) {
      filtered = filtered.filter(licensee =>
        (licensee['REFERENCE CODE'] || '').toLowerCase().includes(lowerCaseSearchTerm) ||
        (licensee.NAME || '').toLowerCase().includes(lowerCaseSearchTerm) ||
        (licensee.SURNAME || '').toLowerCase().includes(lowerCaseSearchTerm)
      );
    }

    if (filterMovingOut) {
      filtered = filtered.filter(licensee => {
        const notice = notices.find(notice => notice.referenceCode === licensee['REFERENCE CODE']);
        return !!notice;
      });
    }

    setFilteredLicensees(filtered);
  }, [searchTerm, selectedPropertyCode, filterMovingOut, licensees, notices]);

  const extractPropertyCode = (referenceCode) => {
    const match = referenceCode.match(/^(\d+[A-Za-z]+)/);
    return match ? match[1] : referenceCode;
  };

  const getMovingOutStatus = (referenceCode) => {
    const matchingNotice = notices.find(notice => notice.referenceCode === referenceCode);
    return matchingNotice ? formatDate(matchingNotice.noticeDate) : 'N/A';
  };

  const handleDetailsOpen = (licensee) => {
    setSelectedLicensee(licensee);
    setDetailsOpen(true);
  };

  const handleDeleteOpen = (licensee) => {
    setLicenseeToDelete(licensee);
    setDeleteOpen(true);
  };

  const handleDeleteSubmit = async () => {
    if (licenseeToDelete) {
      const licenseeRef = doc(db, 'licensees', licenseeToDelete.id);
      await deleteDoc(licenseeRef);
      setDeleteOpen(false);
      window.location.reload();
    }
  };

  // Row rendering for react-window
  const RenderedRow = ({ index, style, data }) => {
    const licensee = data[index];
    if (isMobile) {
      return (
        <MobileRowGrid style={style} key={licensee.id}>
          <TextCell>{licensee['REFERENCE CODE'] || 'N/A'}</TextCell>
          <ButtonCell>
            <SmallButton variant="outlined" size="small" onClick={() => handleDetailsOpen(licensee)}>
              View More
            </SmallButton>
          </ButtonCell>
        </MobileRowGrid>
      );
    } else {
      return (
        <RowGrid style={style} key={licensee.id}>
          <TextCell>{licensee['REFERENCE CODE'] || 'N/A'}</TextCell>
          <TextCell>{licensee.NAME || 'N/A'}</TextCell>
          <TextCell>{licensee.SURNAME || 'N/A'}</TextCell>
          <TextCell>{licensee.DEPOSIT || 'N/A'}</TextCell>
          <TextCell>{licensee.RENT || 'N/A'}</TextCell>
          <TextCell>{licensee['PHONE NUMBER'] || 'N/A'}</TextCell>
          <TextCell>{getMovingOutStatus(licensee['REFERENCE CODE'])}</TextCell>
          <ButtonCell>
            <SmallButton variant="outlined" size="small" onClick={() => handleDetailsOpen(licensee)}>
              Details
            </SmallButton>
          </ButtonCell>
          <ButtonCell>
            <SmallButton variant="outlined" size="small" color="error" onClick={() => handleDeleteOpen(licensee)}>
              Delete
            </SmallButton>
          </ButtonCell>
        </RowGrid>
      );
    }
  };

  if (loading) {
    return (
      <Container maxWidth="sm">
        <Box sx={{ display: 'flex', justifyContent: 'center', mt: 4 }}>
          <CircularProgress />
        </Box>
      </Container>
    );
  }

  return (
    <Container sx={{ width: '100%', maxWidth: '100%', overflowX: 'hidden' }}>
      <Box sx={{ width: '100%', maxWidth: '100%' }}>
        <Typography variant="h4" component="h4" gutterBottom>
          Licensee List
        </Typography>

        <FormControl fullWidth sx={{ mb: 2 }}>
          <InputLabel>Property</InputLabel>
          <Select
            value={selectedPropertyCode}
            onChange={(event) => setSelectedPropertyCode(event.target.value)}
          >
            <MenuItem value="">
              <em>All Properties</em>
            </MenuItem>
            {propertyCodes.map(code => (
              <MenuItem key={code} value={code}>
                {code}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <Box
          sx={{
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row', // Stack vertically on mobile
            alignItems: isMobile ? 'stretch' : 'center', // Ensure proper alignment
            gap: isMobile ? 1 : 2, // Add gap between elements
            mb: 2,
          }}
        >
          <TextField
            variant="outlined"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            fullWidth
            InputProps={{
              endAdornment: (
                <IconButton>
                  <SearchIcon />
                </IconButton>
              ),
            }}
            sx={{
              flexGrow: 1,
              height: '56px',
              mb: isMobile ? 1 : 0, // Add margin-bottom on mobile to separate elements
            }}
          />
          <Button
            variant={filterMovingOut ? 'contained' : 'outlined'}
            color="primary"
            onClick={() => setFilterMovingOut(prev => !prev)}
            sx={{
              height: '56px',
              padding: '6px 12px',
              textTransform: 'none',
              width: isMobile ? '100%' : 'auto', // Full width on mobile
            }}
          >
            {filterMovingOut ? 'Show All' : 'Show Moving'}
          </Button>
        </Box>

        <ScrollableContainer>
          <List
            height={600}
            itemCount={filteredLicensees.length}
            itemSize={50}
            width="100%"
            itemData={filteredLicensees}
          >
            {RenderedRow}
          </List>
        </ScrollableContainer>
      </Box>

      {/* Details Dialog */}
      <Dialog open={detailsOpen} onClose={() => setDetailsOpen(false)} maxWidth="md" fullWidth>
        <DialogTitle>Licensee Details</DialogTitle>
        <DialogContent>
          {selectedLicensee && (
            <Box>
              <Typography variant="h6">Reference Code: {selectedLicensee['REFERENCE CODE']}</Typography>
              <Typography>Name: {selectedLicensee.NAME}</Typography>
              <Typography>Surname: {selectedLicensee.SURNAME}</Typography>
              <Typography>Rent: {selectedLicensee.RENT}</Typography>
              <Typography>Deposit: {selectedLicensee.DEPOSIT}</Typography>
              <Typography>Phone Number: {selectedLicensee['PHONE NUMBER']}</Typography>
              <Typography>Moved In: {formatDate(selectedLicensee['MOVED IN'])}</Typography>
              <Typography>Email: {selectedLicensee.EMAIL}</Typography>
              <Typography>Eircode: {selectedLicensee.EIRCODE}</Typography>
              <Typography>Address: {selectedLicensee.ADDRESS}</Typography>
              <Typography>Moving Out: {getMovingOutStatus(selectedLicensee['REFERENCE CODE']) || 'N/A'}</Typography>
            </Box>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDetailsOpen(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Dialog */}
      <Dialog open={deleteOpen} onClose={() => setDeleteOpen(false)} maxWidth="sm" fullWidth>
        <DialogTitle>Delete Licensee</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this licensee?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteOpen(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleDeleteSubmit} color="error">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default LicenseeList;
