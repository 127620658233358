import { getAuth } from 'firebase/auth';
import PizZip from 'pizzip';
import Docxtemplater from 'docxtemplater';
import { saveAs } from 'file-saver';
import axios from 'axios';

// Function to get Firebase ID token for authenticated user
async function getAuthToken() {
  const auth = getAuth();
  const token = await auth.currentUser.getIdToken(); // Get the ID token from the current user
  return token;
}

// Add token to headers for secure requests
async function secureRequest(url, data) {
  const token = await getAuthToken();
  return axios.post(url, data, {
    headers: {
      Authorization: `Bearer ${token}`, // Include the token in the Authorization header
    },
  });
}

export async function generateContract(data) {
  try {
    // Fetch the DOCX template for the contract
    const response = await fetch('/templates/contract_to_fill.docx');
    const content = await response.arrayBuffer();
    const zip = new PizZip(content);
    const doc = new Docxtemplater(zip);

    // Prepare the data to replace in the template
    const formData = {
      today_date: new Date().toLocaleDateString(),
      today_date2: new Date().toLocaleDateString(),
      property_address: data.propertyAddress,
      room: data.room,
      reference_code: data.referenceCode,
      licensee_name: data.licenseeName,
      bank_details: data.bankDetails,
      rent_amount: data.rentAmount,
      deposit_amount: data.depositAmount,
      start_date: data.startDate,
      end_date: data.endDate,
      term: data.term,
      email_address: data.emailAddress,
    };

    // Set the template data and render the document
    doc.setData(formData);
    doc.render();

    // Generate the DOCX file as a Blob
    const buffer = doc.getZip().generate({ type: 'blob' });

    return buffer; // Return the Blob for upload
  } catch (error) {
    console.error('Error generating contract:', error);
  }
}

export async function generateProofOfAddress(data) {
  try {
    // Fetch the DOCX template for the proof of address
    const response = await fetch('/templates/proof_of_address.docx');
    const content = await response.arrayBuffer();
    const zip = new PizZip(content);
    const doc = new Docxtemplater(zip);

    // Prepare the data to replace in the template
    const formData = {
      name: data.licenseeName,
      date: new Date().toLocaleDateString(),
      address: data.propertyAddress,
      move_in_date: data.startDate,
      rent: data.rentAmount,
      deposit: data.depositAmount,
      name2: data.licenseeName,
      name3: data.licenseeName,
      passport_number: data.passportNumber,
    };

    // Set the template data and render the document
    doc.setData(formData);
    doc.render();

    // Generate the DOCX file as a Blob
    const buffer = doc.getZip().generate({ type: 'blob' });

    return buffer; // Return the Blob for upload if needed
  } catch (error) {
    console.error('Error generating proof of address:', error);
  }
}

// Call the Firebase function to convert DOCX to PDF
export async function convertDocxToPdf(docxUrl) {
  const functionUrl = 'https://europe-west1-lobi-portal.cloudfunctions.net/convertDocxToPdf';
  return await secureRequest(functionUrl, { docxUrl });
}
