import React, { useState, useEffect } from 'react';
import { collection, query, where, getDocs, updateDoc, doc } from 'firebase/firestore';
import { db } from '../firebase';
import {
  Container, Typography, Button, Box, CircularProgress
} from '@mui/material';

const ApproveReports = () => {
  const [reports, setReports] = useState([]);
  const [approvedReports, setApprovedReports] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchReports = async () => {
      setLoading(true);
      try {
        // Fetch reports awaiting approval
        const awaitingApprovalQuery = query(collection(db, 'maintenance'), where('status', '==', 'Awaiting Approval'));
        const awaitingSnapshot = await getDocs(awaitingApprovalQuery);
        const awaitingReports = awaitingSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

        // Fetch approved reports
        const approvedQuery = query(collection(db, 'maintenance'), where('status', '==', 'Approved'));
        const approvedSnapshot = await getDocs(approvedQuery);
        const approvedReports = approvedSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

        setReports(awaitingReports); // Display awaiting approval reports at the top
        setApprovedReports(approvedReports); // Followed by approved reports
      } catch (error) {
        setError('Failed to fetch reports.');
      } finally {
        setLoading(false);
      }
    };
    
    fetchReports();
  }, []);

  const handleApproveReport = async (report) => {
    try {
      const issueRef = doc(db, 'maintenance', report.id);
      const approvalDate = new Date(); // Set the approval date to the current date and time
      await updateDoc(issueRef, {
        status: 'Approved',
        approvalDate: approvalDate,
      });
      
      // Update lists after approval
      setReports(reports.filter(r => r.id !== report.id));
      setApprovedReports([...approvedReports, { ...report, status: 'Approved', approvalDate: approvalDate }]);
    } catch (error) {
      setError('Failed to approve the report.');
    }
  };

  return (
    <Container maxWidth="lg">
      <Typography variant="h4" gutterBottom>
        Approve Maintenance Reports
      </Typography>

      {loading ? (
        <CircularProgress />
      ) : error ? (
        <Typography color="error">{error}</Typography>
      ) : (
        <Box>
          {reports.length === 0 && approvedReports.length === 0 ? (
            <Typography>No reports available.</Typography>
          ) : (
            <>
              {/* Display Awaiting Approval Reports */}
              <Typography variant="h5" gutterBottom>Awaiting Approval</Typography>
              {reports.map((report) => (
                <Box key={report.id} mb={3} p={2} border="1px solid #ddd" borderRadius="8px">
                  <Typography variant="h6">{report.issueType}</Typography>
                  <Typography variant="body1">{report.resolutionDescription || 'No description provided'}</Typography>
                  <Typography variant="body2">Location: {report.location || 'Unknown'}</Typography>
                  <Typography variant="body2">Reference Code: {report.referenceCode || 'N/A'}</Typography>
                  <Typography variant="body2">Resolved At: {report.resolvedAt?.toDate ? report.resolvedAt.toDate().toLocaleString() : 'N/A'}</Typography>
                  <Box mt={2}>
                    <Typography variant="body2">Photos:</Typography>
                    {report.resolutionPhotos && report.resolutionPhotos.map((photoUrl, index) => (
                      <img key={index} src={photoUrl} alt={`Report photo ${index + 1}`} width="150" style={{ marginRight: '10px' }} />
                    ))}
                  </Box>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleApproveReport(report)}
                    sx={{ mt: 2 }}
                  >
                    Approve
                  </Button>
                </Box>
              ))}

              {/* Display Approved Reports */}
              <Typography variant="h5" gutterBottom>Approved Reports</Typography>
              {approvedReports.map((report) => (
                <Box key={report.id} mb={3} p={2} border="1px solid #ddd" borderRadius="8px">
                  <Typography variant="h6">{report.issueType}</Typography>
                  <Typography variant="body1">{report.resolutionDescription || 'No description provided'}</Typography>
                  <Typography variant="body2">Location: {report.location || 'Unknown'}</Typography>
                  <Typography variant="body2">Reference Code: {report.referenceCode || 'N/A'}</Typography>
                  <Typography variant="body2">Resolved At: {report.resolvedAt?.toDate ? report.resolvedAt.toDate().toLocaleString() : 'N/A'}</Typography>
                  <Typography variant="body2">Approved At: {report.approvalDate ? new Date(report.approvalDate.seconds * 1000).toLocaleString() : 'N/A'}</Typography>
                  <Box mt={2}>
                    <Typography variant="body2">Photos:</Typography>
                    {report.resolutionPhotos && report.resolutionPhotos.map((photoUrl, index) => (
                      <img key={index} src={photoUrl} alt={`Report photo ${index + 1}`} width="150" style={{ marginRight: '10px' }} />
                    ))}
                  </Box>
                </Box>
              ))}
            </>
          )}
        </Box>
      )}
    </Container>
  );
};

export default ApproveReports;
