import React, { useState } from 'react';
import { updateDoc, doc } from 'firebase/firestore';
import { db, storage } from '../firebase'; // Update with your firebase configuration
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import {
  Dialog, DialogActions, DialogContent, DialogTitle, TextField,
  Button, CircularProgress, Box, Typography
} from '@mui/material';

function CloseIssueForm({ open, onClose, issue, onSubmit }) {
  const [description, setDescription] = useState('');
  const [photos, setPhotos] = useState([]);
  const [uploading, setUploading] = useState(false);

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
  };

  const handlePhotoChange = (event) => {
    setPhotos(event.target.files);
  };

  const handleSubmit = async () => {
    if (!description.trim() || photos.length === 0) {
      alert('Please provide a description and at least one photo.');
      return;
    }

    setUploading(true);
    const photoUrls = [];

    try {
      // Upload photos to Firebase Storage
      for (const file of photos) {
        const photoRef = ref(storage, `issues/${issue.id}/${file.name}`);
        await uploadBytes(photoRef, file);
        const photoUrl = await getDownloadURL(photoRef);
        photoUrls.push(photoUrl);
      }

      // Update the issue in Firestore with resolution details
      const issueRef = doc(db, 'maintenance', issue.id);
      await updateDoc(issueRef, {
        status: 'Awaiting Approval',
        resolutionDescription: description,
        resolutionPhotos: photoUrls,
        resolvedAt: new Date(),
      });

      // Notify parent component of submission
      onSubmit();
    } catch (error) {
      console.error('Error submitting resolution:', error);
    } finally {
      setUploading(false);
      onClose();
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Close Issue</DialogTitle>
      <DialogContent>
        <TextField
          label="Resolution Description"
          multiline
          rows={4}
          value={description}
          onChange={handleDescriptionChange}
          fullWidth
          variant="outlined"
          margin="normal"
        />

        <Typography variant="subtitle1" gutterBottom>
          Upload Photos (at least one required)
        </Typography>
        <input
          type="file"
          multiple
          onChange={handlePhotoChange}
          accept="image/*"
        />

        {uploading && <CircularProgress size={24} />}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">Cancel</Button>
        <Button onClick={handleSubmit} color="primary" variant="contained" disabled={uploading}>
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CloseIssueForm;
