import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#333333', // Dark gray as the primary color
    },
    secondary: {
      main: '#ff4081', // Pink as the secondary color
    },
    background: {
      default: '#f4f4f4', // Soft light gray background
      paper: '#ffffff', // Clean white for paper elements
    },
    text: {
      primary: '#333333', // Dark gray text color
      secondary: '#666666', // Lighter gray for secondary text
    },
  },
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    h1: {
      fontSize: '2.5rem',
      fontWeight: 600,
      color: '#333333', // Dark gray for h1
      letterSpacing: '0.5px', // Slight letter spacing for elegance
      // Mobile adjustment
      '@media (max-width:600px)': {
        fontSize: '1.75rem',
      },
    },
    h2: {
      fontSize: '2rem',
      fontWeight: 500,
      color: '#333333', // Dark gray for h2
      letterSpacing: '0.25px',
      // Mobile adjustment
      '@media (max-width:600px)': {
        fontSize: '1.5rem',
      },
    },
    body1: {
      fontSize: '1rem',
      color: '#666666', // Lighter gray for body text
      lineHeight: 1.75,
      // Mobile adjustment
      '@media (max-width:600px)': {
        fontSize: '0.9rem',
        lineHeight: 1.6,
      },
    },
    body2: {
      fontSize: '0.8rem',
      color: '#666666', // Slightly smaller secondary body text
    },
    button: {
      textTransform: 'uppercase', // Make buttons text uppercase for a clean look
      fontWeight: 600, // Bold button text
    },
  },
  breakpoints: {
    values: {
      xs: 0, // Extra small devices (phones)
      sm: 600, // Small devices (tablets)
      md: 960, // Medium devices (desktops)
      lg: 1280, // Large devices (large desktops)
      xl: 1920, // Extra large devices
    },
  },
  components: {
    MuiSelect: {
      styleOverrides: {
        root: {
          fontSize: '0.75rem', // Smaller font for all Select inputs
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: '0.75rem', // Smaller font for all MenuItems
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '6px', // Slightly rounded buttons
          padding: '4px 8px', // Adjust padding to ensure text fits better
          fontSize: '0.75rem', // Smaller font size to make text fit
          // Mobile adjustments
          '@media (max-width:600px)': {
            padding: '8px 12px',
            fontSize: '0.875rem',
          },
        },
        containedPrimary: {
          backgroundColor: '#333333', // Dark gray for primary buttons
          color: '#ffffff', // White text
          '&:hover': {
            backgroundColor: '#1e1e1e', // Slightly darker gray on hover
          },
          minWidth: '100px', // Ensure enough space for the button text
          textTransform: 'none', // Prevent uppercase transformation
        },
        outlinedPrimary: {
          borderColor: '#333333', // Dark gray outline for outlined buttons
          color: '#333333', // Dark gray text for outlined buttons
          '&:hover': {
            backgroundColor: '#f4f4f4', // Light gray background on hover
          },
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          marginBottom: '1.25rem', // Slightly more space between fields
          '& .MuiOutlinedInput-root': {
            borderRadius: '8px', // Smooth, rounded input fields
            '& fieldset': {
              borderColor: '#cccccc', // Light gray border for fields
            },
            '&:hover fieldset': {
              borderColor: '#333333', // Dark gray border on hover
            },
            '&.Mui-focused fieldset': {
              borderColor: '#333333', // Dark gray border when focused
            },
          },
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color: '#666666', // Lighter gray for input labels
          '&.Mui-focused': {
            color: '#333333', // Dark gray for focused labels
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderRight: 'none',  // Remove vertical borders
          borderBottom: '1px solid #ddd',  // Keep horizontal borders
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          borderCollapse: 'collapse',  // Ensure the table behaves with only horizontal lines
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          padding: '8px', // Extra padding inside paper components
          borderRadius: '1px', // Smooth, rounded corners
          boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)', // Softer shadow for a more modern feel
        },
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: '#333333', // Dark gray app bar
          color: '#ffffff', // White text for app bar
          boxShadow: '0px 2px 6px rgba(0, 0, 0, 0.15)', // Softer shadow for the app bar
          // Mobile adjustment
          '@media (max-width:600px)': {
            height: '56px',  // Adjust app bar height for mobile
            fontSize: '0.875rem',
          },
        },
      },
    },
    MuiContainer: {
      styleOverrides: {
        root: {
          maxWidth: '90% !important',  // Ensure container never exceeds screen width
          padding: '0 16px',            // Ensure some padding but prevent overflow
          overflowX: 'hidden',          // Hide any possible horizontal overflow
          boxSizing: 'border-box',      // Make sure padding doesn't contribute to the width
          // Mobile adjustment
          '@media (max-width:600px)': {
            padding: '0 8px',
          },
        },
      },
    },
    MuiGrid: {
      styleOverrides: {
        root: {
          flexWrap: 'wrap',             // Ensure grid items wrap to prevent overflow
          width: '80%',                // Ensure the grid doesn't extend beyond screen width
          margin: '0 !important',       // Prevent any extra margin that might cause overflow
          padding: '0 !important',      // Remove padding to prevent overflow
          boxSizing: 'border-box',      // Include padding and border in the element's width and height
          // Mobile adjustment
          '@media (max-width:600px)': {
            flexDirection: 'column', // Stack grid items vertically on small screens
          },
        },
        item: {
          minWidth: 0,                  // Ensure grid items don't extend beyond available space
          wordWrap: 'break-word',       // Prevent long words from overflowing
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: '#333333', // Dark gray tooltip
          color: '#ffffff', // White text for tooltips
          fontSize: '0.875rem', // Slightly larger tooltip text
          padding: '8px 12px', // More padding for comfort
        },
      },
    },
  },
});

export default theme;