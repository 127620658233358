  import React, { useState, useEffect } from 'react';
  import { Container, TextField, Button, Typography, Box, Alert, Paper, LinearProgress, FormControlLabel, Checkbox, Link } from '@mui/material';
  import { useNavigate } from 'react-router-dom';
  import { storage, db } from '../firebase';
  import { ref as storageRef, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
  import { doc, setDoc } from 'firebase/firestore';
  import { useAuth } from '../contexts/AuthContext';
  import axios from 'axios';

  function Register() {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordError, setPasswordError] = useState('');  // For displaying password error
    const [referenceCode, setReferenceCode] = useState('');
    const { signup } = useAuth();
    const navigate = useNavigate();
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [idFile, setIdFile] = useState(null);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [statusMessage, setStatusMessage] = useState('');
    const [tempFileId, setTempFileId] = useState(null);
    const [gdprConsent, setGdprConsent] = useState(false);

    const [cloudVisionApiKey, setCloudVisionApiKey] = useState('');
    const [openAiApiKey, setOpenAiApiKey] = useState('');

    // // Fetch API keys from Firebase function
    // useEffect(() => {
    //   const fetchApiKeys = async () => {
    //     try {
    //       const response = await axios.get('https://us-central1-lobi-portal.cloudfunctions.net/getApiKeys');
    //       setGoogleMapsApiKey(response.data.googleMapsApiKey);
    //       setCloudVisionApiKey(response.data.cloudVisionApiKey);
    //       setOpenAiApiKey(response.data.openAiApiKey);
    //     } catch (error) {
    //       console.error('Error fetching API keys:', error);
    //     }
    //   };
    //   fetchApiKeys();
    // }, []);

    // Password validation logic
    const validatePassword = (password) => {
      if (password.length < 6) {
        return 'Password must be at least 6 characters long';
      }
      if (!/[A-Z]/.test(password)) {
        return 'Password must contain at least one uppercase letter';
      }
      if (!/[0-9]/.test(password)) {
        return 'Password must contain at least one number';
      }
      return ''; // No errors
    };

    // Handle registration form submit
    const handleSubmit = async (e) => {
      e.preventDefault();

      if (!gdprConsent) {
        setError('You must consent to our GDPR policy before registering.');
        return;
      }

      // Validate password
      const passwordValidationMessage = validatePassword(password);
      if (passwordValidationMessage) {
        setPasswordError(passwordValidationMessage);
        return;
      }

      setError('');
      setPasswordError('');  // Clear password error if valid
      setStatusMessage('Starting registration process...');
      setLoading(true);

      try {
        const user = await signup(email, password);
        console.log('User created:', user.uid);

        const docData = {
          fullName: `${firstName} ${lastName}`,
          email,
          referenceCode,
          role: 'user',
          status: 'pending',
          tempFileId,
        };

        await setDoc(doc(db, 'users', user.uid), docData);

        setStatusMessage('Registration successful!');
        navigate('/registration-success');
      } catch (err) {
        console.error('Error during registration:', err);
        setError('Failed to create an account. Please try again.');
        setLoading(false);
      }
    };

    // Handle file upload and generate temp file ID
    const handleFileChange = async (e) => {
      setIdFile(e.target.files[0]);
      setStatusMessage('Uploading ID image...');

      try {
        const tempFileId = `temp-${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;
        setTempFileId(tempFileId);

        const tempFilePath = `unverified-uploads/${tempFileId}.jpeg`;
        const tempFileRef = storageRef(storage, tempFilePath);

        const uploadTask = uploadBytesResumable(tempFileRef, e.target.files[0]);

        uploadTask.on(
          'state_changed',
          (snapshot) => {
            const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            setUploadProgress(progress);
            setStatusMessage(`Uploading ID image: ${progress}% completed.`);
          },
          (error) => {
            console.error('Image upload error:', error);
            setStatusMessage('Error uploading ID image.');
          },
          async () => {
            const publicImageUrl = await getDownloadURL(uploadTask.snapshot.ref);
            console.log('Image uploaded successfully:', publicImageUrl);
            setStatusMessage('ID image uploaded successfully.');

            // After the image is uploaded, continue with parsing the text
            // await processImageWithVisionAndGPT(publicImageUrl);
          }
        );
      } catch (err) {
        console.error('Error during image handling:', err);
        setStatusMessage('Error during image upload process.');
      }
    };

    // // Process the image with Google Cloud Vision and OpenAI GPT
    // const processImageWithVisionAndGPT = async (imageUrl) => {
    //   const extractedText = await callGoogleVisionAPI(imageUrl);

    //   if (extractedText) {
    //     console.log('Extracted Text from Google Vision API:', extractedText);
    //     setStatusMessage('Text extracted from ID. Analyzing...');

    //     const nameData = await callOpenAIForName(extractedText);

    //     if (nameData) {
    //       console.log('Parsed Name and Surname from GPT:', nameData);
    //       setStatusMessage('Name and Surname successfully extracted from text.');
    //       setFirstName(nameData.firstName);
    //       setLastName(nameData.lastName);
    //     } else {
    //       setStatusMessage('Failed to extract Name and Surname from text.');
    //     }
    //   } else {
    //     setStatusMessage('Failed to extract text from ID.');
    //   }
    // };

    // Google Cloud Vision API call
    // const callGoogleVisionAPI = async (imageUrl) => {
    //   try {
    //     const requestPayload = {
    //       requests: [
    //         {
    //           image: {
    //             source: {
    //               imageUri: imageUrl,
    //             },
    //           },
    //           features: [
    //             {
    //               type: 'TEXT_DETECTION',
    //             },
    //           ],
    //         },
    //       ],
    //     };

    //     const response = await axios.post(
    //       `https://vision.googleapis.com/v1/images:annotate?key=${cloudVisionApiKey}`,
    //       requestPayload,
    //       {
    //         headers: {
    //           'Content-Type': 'application/json',
    //         },
    //       }
    //     );

    //     const annotations = response.data.responses[0].textAnnotations;
    //     if (annotations && annotations.length > 0) {
    //       return annotations[0].description;
    //     } else {
    //       console.error('No annotations found in the response');
    //       return null;
    //     }
    //   } catch (error) {
    //     console.error('Error calling Google Vision API:', error.response?.data || error.message);
    //     return null;
    //   }
    // };

    // // OpenAI GPT API call to extract Name and Surname
    // const callOpenAIForName = async (extractedText) => {
    //   try {
    //     const gptResponse = await axios.post(
    //       'https://api.openai.com/v1/chat/completions',
    //       {
    //         model: 'gpt-4',
    //         messages: [
    //           {
    //             role: 'system',
    //             content:
    //               'You are an assistant that extracts "First Name" and "Surname" from text and provides them in a structured format.',
    //           },
    //           {
    //             role: 'user',
    //             content: `Here is some extracted text from an ID card: "${extractedText}". Can you find the "First Name" and "Surname" from this text and provide it in this format: [First Name: NAME] [Surname: SURNAME]?`,
    //           },
    //         ],
    //       },
    //       {
    //         headers: {
    //           'Content-Type': 'application/json',
    //           Authorization: `Bearer ${openAiApiKey}`,
    //         },
    //       }
    //     );

    //     const gptResult = gptResponse.data.choices[0].message.content;
    //     const firstNameMatch = gptResult.match(/\[First Name:\s*([^\]]+)\]/);
    //     const lastNameMatch = gptResult.match(/\[Surname:\s*([^\]]+)\]/);

    //     return {
    //       firstName: firstNameMatch ? firstNameMatch[1].trim() : '',
    //       lastName: lastNameMatch ? lastNameMatch[1].trim() : '',
    //     };
    //   } catch (error) {
    //     console.error('Error calling OpenAI GPT:', error.response?.data || error.message);
    //     return null;
    //   }
    // };

    return (
      <Container maxWidth="sm" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <Paper elevation={3} sx={{ p: 3 }}>
          <Box sx={{ mt: 4, mb: 4, textAlign: 'center' }}>
            <Typography variant="h4" component="h1" gutterBottom>
              Register
            </Typography>
            {error && <Alert severity="error">{error}</Alert>}
            {passwordError && <Alert severity="error">{passwordError}</Alert>}
            {statusMessage && <Alert severity="info">{statusMessage}</Alert>}
            <Button variant="contained" component="label" fullWidth sx={{ mt: 2, mb: 2 }}>
              Upload Government ID (optional)
              <input type="file" hidden onChange={handleFileChange} />
            </Button>

            {uploadProgress > 0 && uploadProgress < 100 && (
              <LinearProgress variant="determinate" value={uploadProgress} />
            )}

            <form onSubmit={handleSubmit}>
              <TextField
                fullWidth
                label="First Name"
                type="text"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                required
              />
              <TextField
                fullWidth
                label="Last Name"
                type="text"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                required
              />
              <TextField
                fullWidth
                label="Email"
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
              <TextField
                fullWidth
                label="Password"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                error={!!passwordError}  // Highlight input box if error
                helperText={passwordError}  // Display error message under the input
              />
              <TextField
                fullWidth
                label="Reference Code"
                type="text"
                value={referenceCode}
                onChange={(e) => setReferenceCode(e.target.value)}
                required
              />

              <FormControlLabel
                control={
                  <Checkbox
                    checked={gdprConsent}
                    onChange={(e) => setGdprConsent(e.target.checked)}
                    name="gdprConsent"
                    required
                  />
                }
                label={
                  <>
                    I consent to the storage of my personal information in accordance with the{' '}
                    <Link href="/privacy-policy" target="_blank" rel="noopener">
                      GDPR Policy
                    </Link>.
                  </>
                }
              />

              <Button type="submit" variant="contained" color="primary" fullWidth disabled={loading}>
                {loading ? 'Registering...' : 'Sign Up'}
              </Button>
            </form>
          </Box>
        </Paper>
      </Container>
    );
  }

  export default Register;
