import React, { useEffect, useState } from 'react';
import { collection, onSnapshot, updateDoc, doc, setDoc, getDocs, query, where } from 'firebase/firestore';
import { db } from '../firebase';
import { Link } from 'react-router-dom';
import {
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
  Paper, Typography, InputLabel, Select, MenuItem, FormControl, Container,
  CircularProgress, TextField, Box, Button, Tooltip, TableSortLabel,
  FormControlLabel, FormLabel, FormGroup, Checkbox, Snackbar, Alert,
  Dialog, DialogActions, DialogContent, DialogTitle, Grid
} from '@mui/material';
import { format } from 'date-fns';
import differenceInHours from 'date-fns/differenceInHours';
import { debounce } from 'lodash';
import { getFunctions, httpsCallable } from "firebase/functions";
import './Dashboard.css';
import CloseIssueForm from './CloseIssueForm';


function Dashboard() {
  const [issues, setIssues] = useState([]);
  const [updatingIssueId, setUpdatingIssueId] = useState(null);
  const [updatingAssigneeId, setUpdatingAssigneeId] = useState(null);
  const [trackingIssueId, setTrackingIssueId] = useState(null);  // Track which issue is being tracked
  const [search, setSearch] = useState('');
  const [filteredIssues, setFilteredIssues] = useState([]);
  const [sortConfig, setSortConfig] = useState({ key: 'createdAt', direction: 'desc' });
  const [groupBy, setGroupBy] = useState('property');
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [maintenanceUsers, setMaintenanceUsers] = useState([]);
  const [statusFilter, setStatusFilter] = useState(['Reported', 'In Progress']);
  const [priorityFilter, setPriorityFilter] = useState([]);
  const [propertyFilter, setPropertyFilter] = useState('');
  const [assigneeFilter, setAssigneeFilter] = useState('');
  const [issueTypeFilter, setIssueTypeFilter] = useState('');
  const [dateRangeFilter, setDateRangeFilter] = useState({ startDate: '', endDate: '' });
  const [error, setError] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedIssue, setSelectedIssue] = useState(null);
  const [customMessage, setCustomMessage] = useState('');
  const [selectedLicensee, setSelectedLicensee] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [sendTrackingLink, setSendTrackingLink] = useState(false); 
  const [isTrackingActive, setIsTrackingActive] = useState(false);  
  const [showFilters, setShowFilters] = useState(false); 
  const [sendAssignedEmail, setSendAssignedEmail] = useState(false);  // New state for sending email to assigned staff member
  const [assignedUser, setAssignedUser] = useState(null);  // New state for storing the assigned user
  const [openDialogStep1, setOpenDialogStep1] = useState(false);  // Step 1: Dialog for sending info to assignee
  const [openPreviewAssignee, setOpenPreviewAssignee] = useState(false);  // Preview dialog for assignee email
  const [openDialogStep2, setOpenDialogStep2] = useState(false);  // Step 2: Dialog for sending info to customer
  const [openPreviewCustomer, setOpenPreviewCustomer] = useState(false);  // Preview dialog for customer email
  const [customMessageCustomer, setCustomMessageCustomer] = useState('');  // Custom message for customer
  const [trackingLink, setTrackingLink] = useState('');  // Tracking link for customer email
  const [licenseeDetails, setLicenseeDetails] = useState({});
  const [openLicenseeDialog, setOpenLicenseeDialog] = useState(false);
  const [selectedLicenseeData, setSelectedLicenseeData] = useState(null);
  const [properties, setProperties] = useState([]);
  const [selectedProperty, setSelectedProperty] = useState('');
  const [issueTypes, setIssueTypes] = useState([]); // To store unique issue types
  const [selectedIssueType, setSelectedIssueType] = useState(''); // To store the selected issue type
  const [numberOfPeopleMap, setNumberOfPeopleMap] = useState({});
  const [numberOfPeopleInHouse, setNumberOfPeopleInHouse] = useState(0);
  const [openCloseDialog, setOpenCloseDialog] = useState(false);
  const [closeIssueDescription, setCloseIssueDescription] = useState('');
  const [closeIssuePhotos, setCloseIssuePhotos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [openMediaDialog, setOpenMediaDialog] = useState(null); // State to handle media dialog
  const [openDetailsDialog, setOpenDetailsDialog] = useState(false);
  const [selectedIssueDetails, setSelectedIssueDetails] = useState(null);
  const [openCloseIssueForm, setOpenCloseIssueForm] = useState(false);
  const [currentIssue, setCurrentIssue] = useState(null);


  const handleOpenCloseIssueForm = (issue) => {
    setCurrentIssue(issue);
    setOpenCloseIssueForm(true);
  };
  
  const handleCloseIssueFormSubmit = () => {
    setOpenCloseIssueForm(false);
    setCurrentIssue(null);
    // Refresh issues if necessary
  };
  
  const handleOpenDetails = (issue) => {
    setSelectedIssueDetails(issue); // Set the selected issue for the dialog
    setOpenDetailsDialog(true); // Open the dialog
  };

  const handleCloseDetails = () => {
    setOpenDetailsDialog(false); // Close the dialog
    setSelectedIssueDetails(null); // Clear the selected issue
  };


  const handleCloseDialogOpen = (issue) => {
    setSelectedIssue(issue); // Set the current issue to be closed
    setOpenCloseDialog(true);
  };
  const handleCloseDialogClose = () => {
    setOpenCloseDialog(false);
  };
  const handleDescriptionChange = (event) => {
    setCloseIssueDescription(event.target.value); // Store description input
  };
  
  const handlePhotoUpload = (event) => {
    setCloseIssuePhotos(event.target.files); // Store uploaded photos
  };
  const handleSubmitReport = async () => {
    if (!closeIssueDescription.trim() || closeIssuePhotos.length === 0) {
      alert('Please provide a description and at least one photo.');
      return;
    }
  
    setLoading(true); // Show loading state
    try {
      const issueRef = doc(db, 'maintenance', selectedIssue.id);
  
      // Add logic to upload photos to Firebase Storage here, if needed
      // For simplicity, let's assume photos are just stored as an array of URLs
      const photoUrls = []; // Replace with actual uploaded file URLs
  
      // Update the issue status to "Awaiting Approval"
      await updateDoc(issueRef, {
        status: 'Awaiting Approval',
        reportDescription: closeIssueDescription,
        reportPhotos: photoUrls, // Store URLs of uploaded photos
        reportSubmittedAt: new Date(),
      });
  
      // Close the dialog after submission
      setOpenCloseDialog(false);
      setLoading(false); // Hide loading state
      setCloseIssueDescription(''); // Reset description field
      setCloseIssuePhotos([]); // Reset photos
    } catch (error) {
      console.error('Error submitting report:', error);
      setLoading(false); // Hide loading state
    }
  };
        

  const getNumberOfPeopleInHouse = async (referenceCode) => {
    if (!referenceCode) {
      console.error('Reference code is missing or undefined');
      return 0; // Return 0 if referenceCode is not available
    }
  
    const housePrefix = referenceCode.replace(/\d+$/, ''); // Strip the bedroom number, leaving the house prefix (e.g., "10BR" from "10BR3")
    console.log('House Prefix:', housePrefix);
    try {
      const licenseeRef = query(
        collection(db, 'licensees'),
        where('REFERENCE CODE', '>=', housePrefix),
        where('REFERENCE CODE', '<', housePrefix + 'z') // Ensure we get all references starting with the house prefix
      );
      console.log('Licensee Ref:', licenseeRef);
      
      const licenseeSnapshot = await getDocs(licenseeRef);
      
      if (licenseeSnapshot.empty) {
        return 0;
      }
  
      const bedroomNumbers = licenseeSnapshot.docs.map(doc => {
        const referenceCode = doc.data().REFERENCE_CODE;
        const bedroomNumber = parseInt(referenceCode.replace(housePrefix, ''));
        return bedroomNumber;
      });
      console.log('Bedroom Number:', bedroomNumbers);
  
      const maxBedroomNumber = Math.max(...bedroomNumbers);
      return maxBedroomNumber; 
    } catch (error) {
      console.error("Error fetching licensees:", error);
      return 0;
    }
  };
  


  const handleOpenLicenseeDialog = async (issueId) => {
    const selectedIssue = issues.find(issue => issue.id === issueId); // Get selected issue
    if (selectedIssue && selectedIssue.referenceCode) {
      try {
        // Fetch licensee details from Firestore based on the issue's referenceCode
        const licenseeRef = query(collection(db, 'licensees'), where('REFERENCE CODE', '==', selectedIssue.referenceCode));
        const licenseeSnapshot = await getDocs(licenseeRef);
  
        if (!licenseeSnapshot.empty) {
          const licenseeData = licenseeSnapshot.docs[0]?.data();
          setSelectedLicenseeData(licenseeData); // Set the fetched licensee data
          setOpenLicenseeDialog(true); // Open the dialog
        } else {
          console.log('No licensee found for reference code:', selectedIssue.referenceCode);
          setSelectedLicenseeData(null);  // Clear if no licensee found
          setOpenLicenseeDialog(true); // Open the dialog anyway
        }
      } catch (error) {
        console.error('Error fetching licensee details:', error);
        setError('Failed to fetch licensee information.');
      }
    }
  };
  
  

  const handlePreviewAssignee = () => {
    setOpenDialogStep1(false);  // Close Step 1
    setOpenPreviewAssignee(true);  // Open Assignee Preview
  };
  
  const handleSendAssigneeEmail = async () => {
    console.log('Assignee email send triggered');
    
    try {
      // First, send the email to the assignee
      console.log('sendAssignedEmail:', sendAssignedEmail);
      console.log('selectedIssue:', selectedIssue);
      console.log('assignedUser:', assignedUser);
      if (sendAssignedEmail && selectedIssue && assignedUser) {
        const functions = getFunctions();
        functions.region = "europe-west1";
        const sendReminderEmailCallable = httpsCallable(functions, 'sendReminderEmail');
    
        const assignedEmailData = {
          email: assignedUser.email,
          subject: 'New Maintenance Assignment',
          message: `
            You have been assigned a new maintenance task:\n
            Issue: ${selectedIssue.issueType}\n
            Description: ${selectedIssue.description}\n
            Address: ${selectedLicensee.ADDRESS}\n
            Customer: ${selectedLicensee.NAME}\n
            Phone: ${selectedLicensee['PHONE NUMBER'] || 'N/A'}\n
            \n
            ${customMessage.trim() ? `Additional Message: ${customMessage}` : ''}
          `,
        };
    
        const response = await sendReminderEmailCallable(assignedEmailData);
        if (!response.data.success) throw new Error('Error sending assignee email');
      }
  
      // Close the assignee preview and proceed to Step 2
      setOpenPreviewAssignee(false);
      setOpenDialogStep2(true);  // Move to Step 2 (Customer email)
    } catch (error) {
      console.error('Failed to send the assignee email:', error);
      setError('Failed to send the assignee email.');
      setSnackbarOpen(true);
    }
  };
  
  
  const handleSkipAssignee = () => {
    setOpenDialogStep1(false);
    setOpenDialogStep2(true);  // Open Step 2 (Customer)
  };
  
  const handlePreviewCustomer = () => {
    setOpenDialogStep2(false);  // Close Step 2
    setOpenPreviewCustomer(true);  // Open Customer Preview
  };
  
  const handleSendCustomerEmail = async () => {
    // Logic to send the email to the customer
    setOpenPreviewCustomer(false);
  };
  
  const handleSkipCustomer = () => {
    setOpenDialogStep2(false);
  };
  

  
  // Handle status update
  const handleUpdateStatus = async (issueId, status) => {
    setUpdatingIssueId(issueId);

    try {
      await updateDoc(doc(db, 'maintenance', issueId), { status });
      setIssues(issues.map(issue => (issue.id === issueId ? { ...issue, status } : issue)));
    } catch (error) {
      setError('Failed to update issue status.');
    } finally {
      setUpdatingIssueId(null);
    }
  };
  // Fetch and display multiple file URLs for each issue
  const renderMediaFiles = (fileUrls) => {
    return fileUrls.map((url, index) => (
      <Box key={index} mt={1}>
        {url.includes('.mp4') || url.includes('.webm') ? (
          <video width="150" controls>
            <source src={url} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        ) : (
          <img src={url} alt="Uploaded media" width="150" />
        )}
        <Box mt={1}>
          <Button variant="outlined" href={url} target="_blank" download>
            Download
          </Button>
        </Box>
      </Box>
    ));
  };
  // Function to extract the first line of an address (before the first comma)
  const extractFirstLine = (address) => {
    if (!address) return '';
    return address.split(',')[0]; // Splits the string by comma and returns the first part
  };

  // Handle assignee update
  const handleAssignUser = async (issueId, userId) => {
    setUpdatingAssigneeId(issueId);
  
    try {
      await updateDoc(doc(db, 'maintenance', issueId), { assignee: userId });
      setIssues(issues.map(issue => (issue.id === issueId ? { ...issue, assignee: userId } : issue)));
      
      // Set selected issue and open the dialog for email after assigning the user
      const selected = issues.find(issue => issue.id === issueId);
      setSelectedIssue(selected);
      setOpenDialog(true);  // Open the email dialog
  
      const foundUser = maintenanceUsers.find(user => user.id === userId);
      setAssignedUser(foundUser);  // Save the assigned user
      // Set `sendAssignedEmail` to true when an assignee is chosen
      setSendAssignedEmail(true);  // <-- Set this to true when assigning a user

      // Open the first step of the dialog (Step 1)
      setOpenDialogStep1(true);  // Automatically open the email preview dialog
    } catch (error) {
      setError('Failed to assign user.');
    } finally {
      setUpdatingAssigneeId(null);
    }
  };
  

  // Handle priority update
  const handleUpdatePriority = async (issueId, priority) => {
    try {
      await updateDoc(doc(db, 'maintenance', issueId), { priority });
      setIssues(issues.map(issue => (issue.id === issueId ? { ...issue, priority } : issue)));
    } catch (error) {
      setError('Failed to update issue priority.');
    }
  };
  

  useEffect(() => {
    if (selectedIssue && selectedIssue.referenceCode) {
      getNumberOfPeopleInHouse(selectedIssue.referenceCode)
        .then(numberOfPeople => {
          console.log(`Number of people in the house: ${numberOfPeople}`);
          setNumberOfPeopleInHouse(numberOfPeople); // Update state or display it
        })
        .catch(error => {
          console.error("Error getting number of people:", error);
          setNumberOfPeopleInHouse(0); // Set default value on error
        });
    }
  }, [selectedIssue]);
  


 // Fetch issues and include the number of people for each issue
 useEffect(() => {
  const fetchIssues = async () => {
    const unsubscribe = onSnapshot(collection(db, 'maintenance'), async (snapshot) => {
      const issuesList = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setIssues(issuesList);

      // Fetch the number of people for each issue based on the referenceCode
      const numberOfPeopleData = await Promise.all(
        issuesList.map(async (issue) => {
          const numberOfPeople = await getNumberOfPeopleInHouse(issue.referenceCode);
          return { id: issue.id, numberOfPeople };
        })
      );

      // Map the number of people to the issues
      const numberOfPeopleMap = numberOfPeopleData.reduce((acc, { id, numberOfPeople }) => {
        acc[id] = numberOfPeople;
        return acc;
      }, {});
      setNumberOfPeopleMap(numberOfPeopleMap); // Update the map state
      setLoading(false);
    }, (error) => {
      setError('Failed to load issues');
    });

    return () => unsubscribe();
  };

fetchIssues();
}, []);



  useEffect(() => {
    if (selectedProperty) {
      setFilteredIssues(issues.filter(issue => issue.property === selectedProperty));
    } else {
      setFilteredIssues(issues);  // Show all issues if no property is selected
    }
  }, [selectedProperty, issues]);
  
  

  useEffect(() => {
    const fetchLicenseesForIssues = async () => {
      try {
        // Create an array to hold promises for fetching licensee data
        const licenseePromises = issues.map(async (issue) => {
          if (issue.referenceCode) {
            const licenseeRef = query(collection(db, 'licensees'), where('REFERENCE CODE', '==', issue.referenceCode));
            const licenseeSnapshot = await getDocs(licenseeRef);
  
            if (!licenseeSnapshot.empty) {
              const licenseeData = licenseeSnapshot.docs[0]?.data();
              return { issueId: issue.id, licensee: licenseeData };
            }
          }
          return { issueId: issue.id, licensee: null }; // Return null if no licensee found
        });
  
        // Resolve all promises and update state with licensee details
        const licenseeData = await Promise.all(licenseePromises);
  
        // Update the state with the fetched licensee data
        const licenseeMap = licenseeData.reduce((acc, { issueId, licensee }) => {
          acc[issueId] = licensee;
          return acc;
        }, {});
  
        setLicenseeDetails(licenseeMap); // Store the licensee details in the state
      } catch (error) {
        console.error('Error fetching licensees:', error);
        setError('Failed to fetch licensee information.');
      }
    };
  
    // Call the function to fetch licensee details for all issues
    fetchLicenseesForIssues();
  }, [issues]); // This will run when issues are fetched or updated
  

  // Fetch the user's reference code when the dialog opens
  useEffect(() => {
    const fetchLicenseeFromUser = async () => {
      if (selectedIssue && selectedIssue.referenceCode) {
        console.log('Fetching reference code for issue:', selectedIssue.referenceCode);
    
        try {
          // Fetch the licensee based on the issue's reference code
          const licenseeRef = query(collection(db, 'licensees'), where('REFERENCE CODE', '==', selectedIssue.referenceCode));
          const licenseeSnapshot = await getDocs(licenseeRef);
    
          if (!licenseeSnapshot.empty) {
            const licenseeData = licenseeSnapshot.docs[0]?.data();
            console.log('Licensee fetched:', licenseeData);
            setSelectedLicensee(licenseeData); // Set the selected licensee data
          } else {
            console.log('No licensee found for reference code:', selectedIssue.referenceCode);
            setSelectedLicensee(null);  // Handle no licensee found
          }
        } catch (error) {
          console.error('Error fetching licensee:', error);
          setError('Failed to fetch the licensee.');
        }
      }
    };
  
    // Automatically fetch licensee data on page load if selectedIssue is available
    if (selectedIssue && selectedIssue.referenceCode) {
      fetchLicenseeFromUser();
    }
  }, [selectedIssue]);  // This useEffect runs whenever `selectedIssue` changes
  
  useEffect(() => {
    const fetchIssuesAndTypes = async () => {
      try {
        // Get all issues from Firestore
        const querySnapshot = await getDocs(collection(db, 'maintenance'));
        
        // Extract issue types from the issues
        const allIssues = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  
        // Get unique issue types
        const uniqueIssueTypes = [
          ...new Set(allIssues.map(issue => issue.issueType).filter(Boolean)) // Remove null or undefined values
        ];
        setIssueTypes(uniqueIssueTypes); // Update the issueTypes state with unique values
      } catch (error) {
        console.error('Error fetching issues:', error);
      }
    };
  
    fetchIssuesAndTypes();
  }, []);

  useEffect(() => {
    // Filter issues based on selected issue type
    if (selectedIssueType) {
      setFilteredIssues(issues.filter(issue => issue.issueType === selectedIssueType));
    } else {
      setFilteredIssues(issues); // Show all issues if no type is selected
    }
  }, [selectedIssueType, issues]);
  

  // Fetch issues and listen for real-time updates
  useEffect(() => {
    const unsubscribe = onSnapshot(collection(db, 'maintenance'), (snapshot) => {
      const issuesList = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      
      const issuesWithProperty = issuesList.map(issue => ({
        ...issue,
        property: issue.property || issue.house || 'Unknown'
      }));

      setIssues(issuesWithProperty);
      setLoading(false);
    }, (error) => {
      setError('Failed to load issues');
    });

    return () => unsubscribe();
  }, []);

  // Fetch maintenance users
  useEffect(() => {
    const fetchMaintenanceUsers = async () => {
      try {
        const q = query(collection(db, 'users'), where('role', '==', 'maintenance'));
        const usersSnapshot = await onSnapshot(q, (snapshot) => {
          const maintenanceUsersList = snapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
          setMaintenanceUsers(maintenanceUsersList);
        });
      } catch (error) {
        setError('Failed to load maintenance users');
      }
    };

    fetchMaintenanceUsers();
  }, []);

  // Handle search input changes with debounce
  const handleSearch = debounce((searchValue) => {
    setSearch(searchValue);
  }, 300);

  // Filter issues based on search, status, priority, property, issue type, date range, and assignee filters
  useEffect(() => {
    setFilteredIssues(
      issues.filter(issue => {
        const issueDate = issue.createdAt ? issue.createdAt.toDate() : null;

        const isInDateRange = dateRangeFilter.startDate && dateRangeFilter.endDate
          ? issueDate >= new Date(dateRangeFilter.startDate) && issueDate <= new Date(dateRangeFilter.endDate)
          : true;

        return (
          (issue.property ? issue.property.toLowerCase() : '').includes(search.toLowerCase()) ||
          (issue.issueType ? issue.issueType.toLowerCase() : '').includes(search.toLowerCase()) ||
          (issue.description ? issue.description.toLowerCase() : '').includes(search.toLowerCase()) ||
          (issue.location ? issue.location.toLowerCase() : '').includes(search.toLowerCase()) ||
          (issue.status ? issue.status.toLowerCase() : '').includes(search.toLowerCase())
        ) &&
        statusFilter.includes(issue.status || 'Reported') &&
        (priorityFilter.length === 0 || priorityFilter.includes(issue.priority || 'Medium')) &&
        (propertyFilter === '' || issue.property === propertyFilter) &&
        (issueTypeFilter === '' || issue.issueType === issueTypeFilter) &&
        (assigneeFilter === '' || issue.assignee === assigneeFilter) &&
        isInDateRange
      })
    );
  }, [search, issues, statusFilter, priorityFilter, propertyFilter, issueTypeFilter, assigneeFilter, dateRangeFilter]);

  // Handle tracking of an issue
  const handleTracker = async (issue) => {
    const issueRef = doc(db, 'maintenance', issue.id);
    
    try {
      // Toggle tracking
      const newTrackingStatus = trackingIssueId !== issue.id || !isTrackingActive;
      
      // Set expiration time (e.g., 30 minutes from now)
      const expirationTime = new Date();
      expirationTime.setMinutes(expirationTime.getMinutes() + 60);
      
      // Update Firestore with the tracking status and expiration time
      await updateDoc(issueRef, {
        isTrackingActive: newTrackingStatus,
        trackingExpirationTime: newTrackingStatus ? expirationTime : null,  // Set expiration if active
      });
  
      // Update local state based on the toggle
      if (newTrackingStatus) {
        setSelectedIssue(issue);
        setTrackingIssueId(issue.id);  // Set the new tracking issue ID
      } else {
        setTrackingIssueId(null);  // Clear the tracking issue ID
      }
      setIsTrackingActive(newTrackingStatus);  // Update the tracking status
    } catch (error) {
      setError('Failed to update tracking status.');
    }
  };
  

  // Generate tracking link
  const generateTrackingLink = (issueId) => {
    return `/track/${issueId}`;
  };

  const handleSendEmail = async () => {
    try {
      const functions = getFunctions();
      functions.region = "europe-west1";
      const sendReminderEmailCallable = httpsCallable(functions, 'sendReminderEmail');
  
      if (!selectedLicensee.EMAIL || !customMessage.trim()) {
        throw new Error('Invalid email or message content.');
      }
  
      let emailMessage = customMessage;
  
      if (sendTrackingLink) {
        console.log("Sending tracking link...");
  
        // Set the expiration time for 1 hour from now
        const expirationTime = new Date();
        expirationTime.setHours(expirationTime.getHours() + 1);
  
        // Log expiration time for debugging
        console.log("Expiration Time:", expirationTime);
  
        // Save the tracking information to Firestore with an expiration time
        const trackingRef = doc(db, 'tracking', selectedIssue.id);
        
        // Use reference code instead of userId
        if (selectedLicensee && selectedLicensee['REFERENCE CODE']) {
          console.log("Reference Code:", selectedLicensee['REFERENCE CODE']);
          await setDoc(trackingRef, {
            issueId: selectedIssue.id,
            referenceCode: selectedLicensee['REFERENCE CODE'],  // Use reference code instead of userId
            locationTracking: selectedIssue.locationTracking,
            createdAt: new Date(),
            expiresAt: expirationTime,  // Ensure expirationTime is defined
          });
          console.log("Tracking document saved successfully.");
  
          // Generate the tracking link
          const trackingLink = `${window.location.origin}/track/${selectedIssue.id}`;
          emailMessage += `\nYou can track the maintenance person here: ${trackingLink}\nNote: This link will expire in 1 hour.`;
        } else {
          console.error("Error: selectedLicensee or referenceCode is undefined.");
        }
      }
  
      // Email content
      const emailData = {
        email: selectedLicensee.EMAIL,
        subject: 'Maintenance Personnel Tracking',
        message: emailMessage,
      };
  
      console.log("Sending email with data:", emailData);
  
      const response = await sendReminderEmailCallable(emailData);
  
      if (response.data.success) {
        console.log("Email sent successfully.");
        setOpenDialog(false);
        setSnackbarOpen(true);
        setCustomMessage('');  // Clear the message after successful send
      } else {
        throw new Error('Error sending email');
      }
    } catch (error) {
      console.error('Failed to send the email or tracking information:', error);
      setError('Failed to send the email.');
      setSnackbarOpen(true);
    }
  };

  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    setSortConfig({ key, direction });
  };

  const sortedIssues = [...filteredIssues].sort((a, b) => {
    const aValue = a[sortConfig.key] ? a[sortConfig.key] : '';
    const bValue = b[sortConfig.key] ? b[sortConfig.key] : '';

    if (sortConfig.direction === 'asc') {
      return aValue > bValue ? 1 : -1;
    }
    return aValue < bValue ? 1 : -1;
  });

  const groupIssues = (issues) => {
    return issues.reduce((group, issue) => {
      const key = groupBy === 'property' ? issue.property || 'Unknown' : issue.status || 'Reported';
      if (!group[key]) {
        group[key] = [];
      }
      group[key].push(issue);
      return group;
    }, {});
  };

  const sortByStatus = (issues) => {
    const statusOrder = { 'Reported': 1, 'In Progress': 2, 'Resolved': 3 };
    return issues.sort((a, b) => {
      const aStatus = statusOrder[a.status] || 4;
      const bStatus = statusOrder[b.status] || 4;
      return aStatus - bStatus;
    });
  };

  const groupedIssues = groupIssues(sortedIssues);

  if (loading) {
    return <Typography variant="h6">Loading data...</Typography>;
  }

  const toggleCardContent = (group, index) => {
    setIssues(issues.map((issue, i) => (
      issue.id === groupedIssues[group][index].id ? { ...issue, isOpen: !issue.isOpen } : issue
    )));
  };

  const handleStatusFilterChange = (status, isChecked) => {
    setStatusFilter(prevFilter => {
      if (isChecked) {
        return [...prevFilter, status];
      } else {
        return prevFilter.filter(s => s !== status);
      }
    });
  };

  const handlePriorityFilterChange = (priority, isChecked) => {
    setPriorityFilter(prevFilter => {
      if (isChecked) {
        return [...prevFilter, priority];
      } else {
        return prevFilter.filter(p => p !== priority);
      }
    });
  };

  return (
    <Container maxWidth="lg">
      <Typography variant="h4" gutterBottom>
        Maintenance
      </Typography>

      <Link to="/video-management" style={{ textDecoration: 'none' }}>
        <Button variant="contained" color="primary" sx={{ mb: 3 }}>
          Videos for Licensees
        </Button>
      </Link>
      <Link to="/report-issue" style={{ textDecoration: 'none' }}>
        <Button variant="contained" color="secondary" sx={{ mb: 3, ml: 2 }}>
          Submit Issue
        </Button>
    </Link>
    <Link to="/approve-reports" style={{ textDecoration: 'none' }}>
        <Button variant="contained" color="secondary" sx={{ mb: 3, ml: 2 }}>
          Approve Reports
        </Button>
    </Link>



{/* Filters Section */}
{isMobile ? (
              <>
                <Button
                  variant="contained"
                  onClick={() => setShowFilters(!showFilters)}
                  sx={{ marginBottom: 2 }}
                >
                  {showFilters ? 'Hide Filters' : 'Show Filters'}
                </Button>
                
                {showFilters && (
                  <Grid container spacing={2}>
                    <Grid item xs={12}>
                      <FormControl component="fieldset">
                        <FormLabel component="legend">Filter by Status</FormLabel>
                        <FormGroup row>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={statusFilter.includes('Reported')}
                                onChange={(e) => handleStatusFilterChange('Reported', e.target.checked)}
                              />
                            }
                            label="Reported"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={statusFilter.includes('In Progress')}
                                onChange={(e) => handleStatusFilterChange('In Progress', e.target.checked)}
                              />
                            }
                            label="In Progress"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={statusFilter.includes('Resolved')}
                                onChange={(e) => handleStatusFilterChange('Resolved', e.target.checked)}
                              />
                            }
                            label="Resolved"
                          />
                        </FormGroup>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                      <FormControl component="fieldset">
                        <FormLabel component="legend">Filter by Priority</FormLabel>
                        <FormGroup row>
                      
                        <FormControlLabel
                        control={
                          <Checkbox
                            checked={priorityFilter.includes(5)}
                            onChange={(e) => handlePriorityFilterChange(5, e.target.checked)}
                          />
                        }
                        label="5"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={priorityFilter.includes(4)}
                            onChange={(e) => handlePriorityFilterChange(4, e.target.checked)}
                          />
                        }
                        label="4"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={priorityFilter.includes(3)}
                            onChange={(e) => handlePriorityFilterChange(3, e.target.checked)}
                          />
                        }
                        label="3"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={priorityFilter.includes(2)}
                            onChange={(e) => handlePriorityFilterChange(2, e.target.checked)}
                          />
                        }
                        label="2"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={priorityFilter.includes(1)}
                            onChange={(e) => handlePriorityFilterChange(1, e.target.checked)}
                          />
                        }
                        label="1"
                      />
                        </FormGroup>
                      </FormControl>
                    </Grid>
                  </Grid>
                )}
              </>
) : (

    <Grid container spacing={2}>


        <Grid item xs={12} md={4}>
          <FormControl fullWidth sx={{ height: '56px' }}>
            <InputLabel id="issue-type-select-label">Issue Type</InputLabel>
            <Select
              labelId="issue-type-select-label"
              value={selectedIssueType}
              onChange={(e) => setSelectedIssueType(e.target.value)}
              fullWidth
            >
              <MenuItem value="">
                <em>All Issue Types</em>
              </MenuItem>
              {issueTypes.map((type, index) => (
                <MenuItem key={index} value={type}>
                  {type}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>


      <Grid item xs={12} md={4}>
        <FormControl fullWidth sx={{ height: '56px' }}>
        <InputLabel id="property-select-label">Property</InputLabel>
          <Select
            labelId="property-select-label" 
            value={selectedProperty}
            onChange={(e) => setSelectedProperty(e.target.value)}
            fullWidth
            sx={{ height: '56px' }}
          >

            <MenuItem value="">All Properties</MenuItem>
            {properties.map((property, index) => (
              <MenuItem key={index} value={property}>
                {property}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
    </Grid>



      <Grid item xs={12} md={4}>
        <TextField
          select
          label="Assignee"
          value={assigneeFilter}
          onChange={(e) => setAssigneeFilter(e.target.value)}
          fullWidth
          variant="outlined"
          InputLabelProps={{ shrink: true }}  // Ensures consistent label positioning
        >
          <MenuItem value="">
            <em>Unassigned</em>
          </MenuItem>
          {maintenanceUsers.map(user => (
            <MenuItem key={user.id} value={user.id}>
              {user.fullName || user.email}
            </MenuItem>
          ))}
        </TextField>
      </Grid>



      <Grid item xs={12} md={6}>
        <FormControl component="fieldset" fullWidth sx={{ height: '56px' }}>
          <FormLabel component="legend">Filter by Status</FormLabel>
          <FormGroup row>
            <FormControlLabel
              control={
                <Checkbox
                  checked={statusFilter.includes('Reported')}
                  onChange={(e) => handleStatusFilterChange('Reported', e.target.checked)}
                />
              }
              label="Reported"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={statusFilter.includes('In Progress')}
                  onChange={(e) => handleStatusFilterChange('In Progress', e.target.checked)}
                />
              }
              label="In Progress"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={statusFilter.includes('Resolved')}
                  onChange={(e) => handleStatusFilterChange('Resolved', e.target.checked)}
                />
              }
              label="Resolved"
            />
          </FormGroup>
        </FormControl>
      </Grid>

      <Grid item xs={12} md={6}>
        <FormControl component="fieldset" fullWidth sx={{ height: '56px' }}>
          <FormLabel component="legend">Filter by Priority</FormLabel>
          <FormGroup row>


          <FormControlLabel
            control={
              <Checkbox
                checked={priorityFilter.includes(5)}
                onChange={(e) => handlePriorityFilterChange(5, e.target.checked)}
              />
            }
            label="5 Emergency"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={priorityFilter.includes(4)}
                onChange={(e) => handlePriorityFilterChange(4, e.target.checked)}
              />
            }
            label="Today"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={priorityFilter.includes(3)}
                onChange={(e) => handlePriorityFilterChange(3, e.target.checked)}
              />
            }
            label="Tomorrow"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={priorityFilter.includes(2)}
                onChange={(e) => handlePriorityFilterChange(2, e.target.checked)}
              />
            }
            label="2/3 Days"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={priorityFilter.includes(1)}
                onChange={(e) => handlePriorityFilterChange(1, e.target.checked)}
              />
            }
            label="1 Week"
          />




          </FormGroup>
        </FormControl>
      </Grid>
    </Grid>
  )}


{/* Table for Issues */}
<Box mt={4}>
  {Object.keys(groupedIssues).map((group) => (
    <Box key={group} mt={4}>
      {!isMobile && (
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                {groupBy === 'status' && <TableCell>Property</TableCell>}
                <TableCell>
                  <TableSortLabel
                    active={sortConfig.key === 'createdAt'}
                    direction={sortConfig.direction}
                    onClick={() => handleSort('createdAt')}
                  >
                    Date
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={sortConfig.key === 'issueType'}
                    direction={sortConfig.direction}
                    onClick={() => handleSort('issueType')}
                  >
                    Issue Type
                  </TableSortLabel>
                </TableCell>
                <TableCell>Location</TableCell>
                <TableCell>Details</TableCell>
                <TableCell>Reference Code</TableCell>
                <TableCell>Thumbnail</TableCell>

                {groupBy === 'property' && <TableCell>Status</TableCell>}
                {groupBy === 'property' && <TableCell>Assignee</TableCell>}
                <TableCell>Priority</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {sortByStatus(groupedIssues[group]).map((issue) => (
                <TableRow key={issue.id}>
                  {groupBy === 'status' && <TableCell>{issue.property}</TableCell>}

                  {/* Date */}
                  <TableCell>{issue.createdAt ? format(issue.createdAt.toDate(), 'yyyy-MM-dd') : 'No Date'}</TableCell>

                  {/* Issue Type */}
                  <TableCell>{issue.issueType}</TableCell>

                  {/* Location */}
                  <TableCell>{issue.location || 'Unknown'}</TableCell>

                
                  <TableCell>
                  <Button variant="outlined" onClick={handleOpenDetails}>
                    Details
                  </Button>

                  
                </TableCell>

                  {/* Licensee Details Button */}
                  <TableCell>
                  <Button variant="outlined" onClick={() => handleOpenLicenseeDialog(issue.id)}>
                    {issue.referenceCode}
                  </Button>

                  </TableCell>

                    {/* Dialog for Issue Details */}
               <Dialog open={openDetailsDialog} onClose={handleCloseDetails} maxWidth="sm" fullWidth>
                <DialogTitle>Issue Details</DialogTitle>
                <DialogContent>
                  <Typography variant="subtitle2" gutterBottom>Subcategory:</Typography>
                  <Typography variant="body2">{issue.subcategory}</Typography>
                  
                  <Typography variant="subtitle2" gutterBottom>Problem:</Typography>
                  <Typography variant="body2">{issue.issue}</Typography>
                  
                  <Typography variant="subtitle2" gutterBottom>Description:</Typography>
                  <Typography variant="body2">{issue.description || 'No description provided'}</Typography>
                  <Typography variant="subtitle2" gutterBottom>No. People In House:</Typography>
                  <Typography variant="body2">{numberOfPeopleMap[issue.id] || 'N/A'}</Typography>

                  <Box mt={2}>
                    <Typography variant="subtitle2" gutterBottom>Media:</Typography>
                    {issue.fileUrls && issue.fileUrls.length > 0 ? (
                      issue.fileUrls.map((url, index) => (
                        url.includes('.mp4') || url.includes('.webm') ? (
                          <video key={index} width="100%" controls>
                            <source src={url} type="video/mp4" />
                            Your browser does not support the video tag.
                          </video>
                        ) : (
                          <img key={index} src={url} alt={`Uploaded media ${index + 1}`} width="100%" />
                        )
                      ))
                    ) : (
                      <Typography>No media available</Typography>
                    )}
                  </Box>
                </DialogContent>
              </Dialog>
                  {/* Licensee Details Dialog */}
                <Dialog open={openLicenseeDialog} onClose={() => setOpenLicenseeDialog(false)}>
                  <DialogTitle>Licensee Details</DialogTitle>
                  <DialogContent>
                    {selectedLicenseeData ? (
                      <>
                        <Typography variant="h6">Name: {selectedLicenseeData.NAME}</Typography>
                        <Typography variant="body1">Email: {selectedLicenseeData.EMAIL}</Typography>
                        <Typography variant="body1">Eircode: {selectedLicenseeData.EIRCODE}</Typography>
                        <Typography variant="body1">Bedroom: {selectedLicenseeData.BEDROOM}</Typography>
                        <Typography variant="body1">Phone: {selectedLicenseeData['PHONE NUMBER']}</Typography>
                        {/* Display any other fields you want */}
                      </>
                    ) : (
                      <Typography>Loading...</Typography>
                    )}
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => setOpenLicenseeDialog(false)} color="primary">Close</Button>
                  </DialogActions>
                </Dialog>

                  {/* Media (Thumbnail view with click to expand) */}
                  <TableCell>
                    {issue.fileUrls && issue.fileUrls.length > 0 ? (
                      <Box>
                        {/* Display the first media item as a thumbnail */}
                        {issue.fileUrls[0].includes('.mp4') || issue.fileUrls[0].includes('.webm') ? (
                          <video width="100" height="80" onClick={() => setOpenMediaDialog(issue.fileUrls)}>
                            <source src={issue.fileUrls[0]} type="video/mp4" />
                            Your browser does not support the video tag.
                          </video>
                        ) : (
                          <img
                            src={issue.fileUrls[0]}
                            alt="Thumbnail"
                            width="100"
                            height="80"
                            onClick={() => setOpenMediaDialog(issue.fileUrls)}
                            style={{ cursor: 'pointer' }}
                          />
                        )}
                      </Box>
                    ) : (
                      <Typography>No media</Typography>
                    )}
                  </TableCell>

                  {/* Status and Assignee, if grouped by property */}
                  {groupBy === 'property' && (
                    <>
                      <TableCell>
                        <FormControl fullWidth variant="outlined" size="small">
                          {updatingIssueId === issue.id ? (
                            <CircularProgress size={24} />
                          ) : (
                            <Select
                              value={issue.status || 'Reported'}
                              onChange={(e) => handleUpdateStatus(issue.id, e.target.value)}
                            >
                              <MenuItem value="Reported">Reported</MenuItem>
                              <MenuItem value="In Progress">In Progress</MenuItem>
                              <MenuItem value="Resolved">Resolved</MenuItem>
                            </Select>
                          )}
                        </FormControl>
                      </TableCell>
                      <TableCell>
                        <FormControl fullWidth variant="outlined" size="small">
                          {updatingAssigneeId === issue.id ? (
                            <CircularProgress size={24} />
                          ) : (
                            <Select
                              value={issue.assignee || ''}
                              onChange={(e) => handleAssignUser(issue.id, e.target.value)}
                              displayEmpty
                            >
                              <MenuItem value=""><em>Unassigned</em></MenuItem>
                              {maintenanceUsers.map(user => (
                                <MenuItem key={user.id} value={user.id}>{user.fullName || user.email}</MenuItem>
                              ))}
                            </Select>
                          )}
                        </FormControl>
                      </TableCell>
                    </>
                  )}

                  {/* Priority */}
                  <TableCell>
                    <FormControl fullWidth variant="outlined" size="small">
                      <Select
                        value={issue.priority || 1}  // Default to 'Within a week' (1)
                        onChange={(e) => handleUpdatePriority(issue.id, e.target.value)}
                      >
                        <MenuItem value={5}>5: Emergency</MenuItem>
                        <MenuItem value={4}>4: Today</MenuItem>
                        <MenuItem value={3}>3: Tomorrow</MenuItem>
                        <MenuItem value={2}>2: In next 2/3 days</MenuItem>
                        <MenuItem value={1}>1: Within a week</MenuItem>
                      </Select>
                    </FormControl>
                  </TableCell>

                  {/* Actions */}
                  <TableCell>
                  <Button
                      variant="outlined"
                      onClick={() => handleOpenCloseIssueForm(issue)}
                    >
                      Close Issue
                    </Button>

                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}

      {/* Licensee Details Dialog */}
      <Dialog open={openLicenseeDialog} onClose={() => setOpenLicenseeDialog(false)}>
        <DialogTitle>Licensee Details</DialogTitle>
        <DialogContent>
          {selectedLicenseeData ? (
            <>
              <Typography variant="h6">Name: {selectedLicenseeData.NAME}</Typography>
              <Typography>Email: {selectedLicenseeData.EMAIL}</Typography>
              <Typography>Eircode: {selectedLicenseeData.EIRCODE}</Typography>
              <Typography>Bedroom: {selectedLicenseeData.BEDROOM}</Typography>
              <Typography>Phone: {selectedLicenseeData['PHONE NUMBER']}</Typography>
            </>
          ) : (
            <Typography>Loading...</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenLicenseeDialog(false)} color="primary">Close</Button>
        </DialogActions>
      </Dialog>

      {/* Media Dialog */}
      <Dialog open={openMediaDialog} onClose={() => setOpenMediaDialog(null)}>
        <DialogTitle>Media Files</DialogTitle>
        <DialogContent>
          {openMediaDialog && openMediaDialog.map((url, index) => (
            <Box key={index} mt={1}>
              {url.includes('.mp4') || url.includes('.webm') ? (
                <video width="300" controls>
                  <source src={url} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              ) : (
                <img src={url} alt={`Media ${index + 1}`} width="300" />
              )}
            </Box>
          ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenMediaDialog(null)} color="primary">Close</Button>
        </DialogActions>
      </Dialog>

    </Box>
  ))}
</Box>






      {/* Error Handling Snackbar */}
      {error && (
        <Snackbar open={!!error} autoHideDuration={6000} onClose={() => setError(null)}>
          <Alert onClose={() => setError(null)} severity="error">
            {error}
          </Alert>
        </Snackbar>
      )}

    <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
      <DialogTitle>Tracking Information</DialogTitle>
      <DialogContent>
        {selectedLicensee ? (
          <>
            <Typography gutterBottom>
              <strong>Sending to:</strong>
            </Typography>
            <Typography>
              <strong>Name:</strong> {selectedLicensee.NAME || 'N/A'}
            </Typography>
            <Typography>
              <strong>Email:</strong> {selectedLicensee.EMAIL || 'N/A'}
            </Typography>
          </>
        ) : (
          <Typography>No licensee information available.</Typography>
        )}

        {/* Checkbox to include the tracking map link */}
        <FormControlLabel
          control={
            <Checkbox
              checked={sendTrackingLink}
              onChange={(e) => setSendTrackingLink(e.target.checked)}
            />
          }
          label="Include tracking link to maintenance personnel's location"
        />

        {sendTrackingLink && (
          <Link to={generateTrackingLink(selectedIssue?.id)} target="_blank">
            <Button variant="contained" color="primary">
              View Map
            </Button>
          </Link>
        )}

        {/* Email sending section */}
        <TextField
          label="Custom Message"
          multiline
          rows={4}
          value={customMessage}
          onChange={(e) => setCustomMessage(e.target.value)}
          fullWidth
          variant="outlined"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpenDialog(false)} color="secondary">Cancel</Button>
        <Button onClick={handleSendEmail} color="primary" variant="contained">
          Send Email
        </Button>
      </DialogActions>
    </Dialog>
        <CloseIssueForm
      open={openCloseIssueForm}
      onClose={() => setOpenCloseIssueForm(false)}
      issue={currentIssue}
      onSubmit={handleCloseIssueFormSubmit}
    />

    </Container>
  );
}

export default Dashboard;
