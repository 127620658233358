import React, { useState, useEffect } from 'react';
import { Container, Typography, Box, Paper, Button, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, CircularProgress } from '@mui/material';
import axios from 'axios';

const CheckReferences = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);

  // Fetch users who submitted references
  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get('https://europe-west1-lobi-portal.cloudfunctions.net/getSubmittedReferences');
        setUsers(response.data.users);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching user references:', error);
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  // Parse document using DocumentAI
  const parseDocument = async (fileUrl) => {
    try {
      const response = await axios.post('https://europe-west1-lobi-portal.cloudfunctions.net/processDocumentAI', {
        documentUrl: fileUrl, // Send the document URL to the cloud function
      });
      return response.data.extractedText; // Extracted text from DocumentAI
    } catch (error) {
      console.error('Error parsing document:', error);
      return null;
    }
  };

  // Create a single PDF report with all documents
  const createPDFReport = async (userId) => {
    try {
      const response = await axios.get(`https://europe-west1-lobi-portal.cloudfunctions.net/getPDFSummary?userId=${userId}`, { responseType: 'blob' });
      const fileURL = window.URL.createObjectURL(new Blob([response.data]));
      const fileLink = document.createElement('a');
      fileLink.href = fileURL;
      fileLink.setAttribute('download', `summary-${userId}.pdf`);
      document.body.appendChild(fileLink);
      fileLink.click();
    } catch (error) {
      console.error('Error downloading PDF summary:', error);
    }
  };

  // Handle Deny action (delete user data)
  const handleDeny = async (userId) => {
    try {
      await axios.delete(`https://europe-west1-lobi-portal.cloudfunctions.net/deleteUserReferences?userId=${userId}`);
      setUsers((prevUsers) => prevUsers.filter((user) => user.id !== userId));
      console.log(`User ${userId} data deleted successfully.`);
    } catch (error) {
      console.error('Error deleting user data:', error);
    }
  };

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Container maxWidth="md">
      <Paper elevation={3} sx={{ p: 3, mt: 5 }}>
        <Typography variant="h5" gutterBottom>
          Submitted References
        </Typography>

        {users.length === 0 ? (
          <Typography>No references have been submitted yet.</Typography>
        ) : (
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell><strong>User</strong></TableCell>
                  <TableCell><strong>Uploaded References</strong></TableCell>
                  <TableCell><strong>Extracted Data</strong></TableCell>
                  <TableCell><strong>Actions</strong></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users.map((user) => (
                  <TableRow key={user.id}>
                    <TableCell>{user.name}</TableCell>
                    <TableCell>
                      {Object.keys(user.references).map((refType) => {
                        const ref = user.references[refType];
                        return (
                          <div key={refType}>
                            {refType}: 
                            <a href={ref.downloadURL} target="_blank" rel="noopener noreferrer">{ref.fileName}</a>
                          </div>
                        );
                      })}
                    </TableCell>
                    <TableCell>
                      {Object.keys(user.references).map((refType) => (
                        <div key={refType}>
                          <Button
                            variant="contained"
                            size="small"
                            onClick={async () => {
                              const extractedText = await parseDocument(user.references[refType].downloadURL);
                              alert(`Extracted info from ${refType}: ${extractedText}`);
                            }}
                            sx={{ mt: 1 }}
                          >
                            Extract {refType} Info
                          </Button>
                        </div>
                      ))}
                    </TableCell>
                    <TableCell>
                      <Button
                        variant="contained"
                        color="primary"
                        sx={{ mb: 1 }}
                        onClick={() => createPDFReport(user.id)}
                      >
                        Generate PDF Report
                      </Button>
                      <Button
                        variant="contained"
                        color="error"
                        onClick={() => handleDeny(user.id)}
                      >
                        Deny
                      </Button>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        )}
      </Paper>
    </Container>
  );
};

export default CheckReferences;
