import React, { useState } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom';
import { Container, TextField, Button, Typography, Box, Alert, Paper, Link } from '@mui/material';
import { db } from '../firebase'; // Import Firestore
import { doc, getDoc } from 'firebase/firestore'; // Firestore functions

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const { login, resetPassword, setUserStatus } = useAuth(); // Destructure resetPassword from AuthContext
  const navigate = useNavigate();
  const [error, setError] = useState('');
  const [message, setMessage] = useState(''); // For showing success message on reset

  // Handle login submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setMessage('');

    try {
      const userCredential = await login(email, password);
      const user = userCredential.user;

      // Fetch the user's document from Firestore to check their status
      const userDocRef = doc(db, 'users', user.uid);
      const userDoc = await getDoc(userDocRef);
      const userData = userDoc.data();

      if (userData && userData.status === 'pending') {
        setError('Your account is pending approval. Please wait for admin approval.');
        return;
      }

      // Update the user status in the AuthContext
      setUserStatus(userData.status);

      // Navigate to home if the user is approved
      navigate('/');
    } catch (err) {
      if (err.message.includes('pending approval')) {
        setError('Your account is pending approval. Please wait for admin approval.');
      } else {
        setError('Failed to log in');
      }
    }
  };

  // Handle password reset
  const handleForgotPassword = async () => {
    if (!email) {
      return setError('Please enter your email address to reset your password.');
    }
    try {
      setError('');
      setMessage('');
      await resetPassword(email);
      setMessage('Check your inbox for further instructions.');
    } catch (err) {
      setError('Failed to reset password');
    }
  };

  // Navigate to Register page
  const handleRegisterRedirect = () => {
    navigate('/register');
  };

  return (
    <Container maxWidth="sm">
      <Paper elevation={3}>
        <Box sx={{ mt: 4, mb: 4 }}>
          <Typography variant="body1" gutterBottom>
            Login
          </Typography>
          {error && <Alert severity="error">{error}</Alert>}
          {message && <Alert severity="success">{message}</Alert>}
          <form onSubmit={handleSubmit}>
            <TextField
              fullWidth
              label="Email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              inputProps={{ 'data-testid': 'email-input' }}
            />
            <TextField
              fullWidth
              label="Password"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              inputProps={{ 'data-testid': 'password-input' }}
            />
            <Button type="submit" variant="contained" color="primary" fullWidth>
              Log In
            </Button>
          </form>
          <Box sx={{ mt: 2 }}>
            <Link href="#" onClick={handleForgotPassword}>
              Forgot Password?
            </Link>
          </Box>
          <Box sx={{ mt: 2 }}>
            <Button variant="outlined" color="secondary" fullWidth onClick={handleRegisterRedirect}>
              Register
            </Button>
          </Box>
        </Box>
      </Paper>
    </Container>
  );
}

export default Login;
