import React, { useState, useEffect } from 'react';
import { ref, uploadBytesResumable, deleteObject, getDownloadURL, listAll } from 'firebase/storage';
import { collection, getDocs } from 'firebase/firestore';
import { db, storage } from '../firebase';
import {
  Container, Typography, Box, Button, TextField, FormControl, Autocomplete, CircularProgress, LinearProgress,
  TableContainer, Table, TableBody, TableCell, TableHead, TableRow, Paper
} from '@mui/material';

function VideoManagement() {
  const [propertys, setPropertys] = useState([]);
  const [categories, setCategories] = useState([
    "Bathroom and Toilet",
    "Kitchen",
    "Heating and Boiler",
    "Water and Leaks",
    "Doors, Garages, and Locks",
    "Internal floors, walls, and ceilings",
    "Electricity",
    "Hot Water",
    "Alarms and Smoke Detectors",
    "Pests/Vermin",
    "Roof",
    "Other"
  ]);
  
  const [selectedProperty, setSelectedProperty] = useState('');
  const [selectedCategory, setSelectedCategory] = useState('');
  const [videoFile, setVideoFile] = useState(null);
  const [videos, setVideos] = useState([]);
  const [loading, setLoading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0); // State to track upload progress

  // Fetch property reference codes from the 'licensees' collection
  useEffect(() => {
    const fetchPropertys = async () => {
      try {
        const licenseeCollection = collection(db, 'licensees');
        const licenseeSnapshot = await getDocs(licenseeCollection);
        const licensees = licenseeSnapshot.docs.map(doc => doc.data());
        const propertyCodes = Array.from(new Set(licensees.map(licensee => {
          return licensee['REFERENCE CODE'].replace(/\d+$/, '');
        })));
        setPropertys(['All houses', ...propertyCodes]); // Add 'All houses' to the list
      } catch (err) {
        console.error('Failed to fetch property codes:', err);
      }
    };
    fetchPropertys();
  }, []);

  // Fetch videos for the selected property and category
  const fetchVideos = async () => {
    if (!selectedCategory) return;

    setLoading(true);  // Set loading to true when fetching videos
    const videoPath = selectedProperty === 'All houses' 
      ? `videos/all-houses/${selectedCategory}`
      : `videos/${selectedProperty}/${selectedCategory}`;
    const videoRef = ref(storage, videoPath);
    const videoList = await listAll(videoRef);
    const videoUrls = await Promise.all(videoList.items.map(item => getDownloadURL(item)));
    setVideos(videoUrls);
    setLoading(false);  // Set loading to false after fetching videos
  };

  // Handle video upload with progress tracking
  const handleUpload = () => {
    if (!videoFile || !selectedProperty || !selectedCategory) {
      alert("Please select a property, category, and video file.");
      return;
    }

    setLoading(true);
    const videoPath = selectedProperty === 'All houses' 
      ? `videos/all-houses/${selectedCategory}/${Date.now()}-${videoFile.name}`
      : `videos/${selectedProperty}/${selectedCategory}/${Date.now()}-${videoFile.name}`;
    const videoRef = ref(storage, videoPath);
    
    // Use uploadBytesResumable to track upload progress
    const uploadTask = uploadBytesResumable(videoRef, videoFile);

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        // Track upload progress
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setUploadProgress(progress);
      },
      (error) => {
        console.error('Error uploading video:', error);
        alert('Failed to upload video. Please try again.');
        setLoading(false);
      },
      async () => {
        // Upload complete
        setVideoFile(null);
        fetchVideos(); // Refresh video list after upload
        setLoading(false);
        setUploadProgress(0); // Reset the progress bar
        alert('Video uploaded successfully!');
      }
    );
  };

  // Handle video deletion
  const handleDelete = async (videoUrl) => {
    const videoRef = ref(storage, videoUrl);
    try {
      await deleteObject(videoRef);
      fetchVideos(); // Refresh video list after deletion
      alert('Video deleted successfully!');
    } catch (error) {
      console.error('Error deleting video:', error);
      alert('Failed to delete video. Please try again.');
    }
  };

  // Update fetchVideos when selectedProperty or selectedCategory changes
  useEffect(() => {
    fetchVideos();
  }, [selectedProperty, selectedCategory]);  // Added selectedCategory to dependency array

  return (
    <Container maxWidth="lg">
      <Typography variant="h4" gutterBottom>
        Video / Photo Management
      </Typography>

      {/* Property and Category Select */}
      <FormControl fullWidth margin="normal">
        <Autocomplete
          options={propertys}
          getOptionLabel={(option) => option}
          value={selectedProperty}
          onChange={(event, newValue) => setSelectedProperty(newValue || '')}
          renderInput={(params) => (
            <TextField {...params} label="Select Property" placeholder="Search Property" required />
          )}
        />
      </FormControl>

      <FormControl fullWidth margin="normal">
        <Autocomplete
          options={categories}
          getOptionLabel={(option) => option}
          value={selectedCategory}
          onChange={(event, newValue) => setSelectedCategory(newValue || '')}
          renderInput={(params) => (
            <TextField {...params} label="Select Category" placeholder="Select Category" required />
          )}
        />
      </FormControl>

      <Box mt={2}>
        <Button
          variant="contained"
          component="label"
          fullWidth
        >
          Select Video
          <input
            type="file"
            hidden
            onChange={(e) => setVideoFile(e.target.files[0])}
          />
        </Button>
        {videoFile && (
          <Typography variant="body1" mt={2}>
            Selected Video: {videoFile.name}
          </Typography>
        )}
      </Box>

      <Box mt={2}>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={handleUpload}
          disabled={loading}
        >
          {loading ? <CircularProgress size={24} /> : 'Upload Video'}
        </Button>
      </Box>

      {/* Progress Bar for Upload */}
      {loading && (
        <Box mt={2}>
          <LinearProgress variant="determinate" value={uploadProgress} />
          <Typography variant="body2" align="center" mt={2}>
            Uploading... {Math.round(uploadProgress)}%
          </Typography>
        </Box>
      )}

      {/* Display existing videos with video previews */}
      <Typography variant="h5" mt={4}>Existing Videos:</Typography>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Video</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {videos.map((videoUrl, index) => (
              <TableRow key={index}>
                <TableCell>
                  {/* Video preview */}
                  <video width="250" height="150" controls>
                    <source src={videoUrl} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </TableCell>
                <TableCell>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => handleDelete(videoUrl)}
                  >
                    Delete
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Container>
  );
}

export default VideoManagement;
