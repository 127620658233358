import React, { useState, useEffect } from 'react';
import { Container, Grid, Typography, Paper, Box, Button, Modal } from '@mui/material';
import { doc, getDocs, updateDoc, collection, query, where } from 'firebase/firestore';
import { db } from '../firebase'; 
import { useAuth } from '../contexts/AuthContext';
import { useNavigate } from 'react-router-dom'; 
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import useMediaQuery from '@mui/material/useMediaQuery';

ChartJS.register(ArcElement, Tooltip, Legend);

const normalizeName = (name) => {
  return name.replace(/\s+/g, ' ').trim().toLowerCase(); 
};

const getMatchStatus = (user, licensee) => {
  if (!licensee || !licensee.name || !licensee.email) return 'red';

  const matches = [];
  const cleanUserFullName = normalizeName(user.fullName);
  const cleanLicenseeFullName = normalizeName(licensee.name);

  if (cleanUserFullName === cleanLicenseeFullName) {
    matches.push(true);
  }

  if (user.email.toLowerCase() === licensee.email.toLowerCase()) {
    matches.push(true);
  }

  return matches.length === 2 ? 'green' : matches.length === 1 ? 'orange' : 'red';
};

function Home() {
  const { currentUser, userRole, userStatus } = useAuth();
  const [pendingUsers, setPendingUsers] = useState([]);
  const [licenseeDetails, setLicenseeDetails] = useState({});
  const [loading, setLoading] = useState(true);
  const [totalTenants, setTotalTenants] = useState(0);
  const [movingOutCount, setMovingOutCount] = useState(0);
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width:600px)'); 
  const [openModal, setOpenModal] = useState(false); 


  useEffect(() => {
    if (!currentUser) {
      navigate('/login');
    }
  }, [currentUser, navigate]);
  
  useEffect(() => {
    if (userRole === 'user') {
      navigate('/licensee-services');
    }
  }, [userRole, navigate]);

  useEffect(() => {
    const fetchTenantsData = async () => {
      try {
        const licenseesSnapshot = await getDocs(collection(db, 'licensees'));
        setTotalTenants(licenseesSnapshot.docs.length);

        const noticesSnapshot = await getDocs(collection(db, 'notices'));
        setMovingOutCount(noticesSnapshot.docs.length);
      } catch (error) {
        console.error('Error fetching tenants data:', error);
      }
    };
    fetchTenantsData();
  }, []);

  useEffect(() => {
    if (userRole === 'admin' || userRole === 'viewings') {
      const fetchPendingUsers = async () => {
        setLoading(true);
        const usersRef = collection(db, 'users');
        const q = query(usersRef, where('status', '==', 'pending'));
        const querySnapshot = await getDocs(q);
        const pendingUsersList = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        }));

        const licenseeData = {};
        for (const user of pendingUsersList) {
          if (user.referenceCode) {
            const refCode = user.referenceCode.toUpperCase();
            const licenseeQuery = query(
              collection(db, 'licensees'),
              where('REFERENCE CODE', '==', refCode)
            );
            const licenseeSnapshot = await getDocs(licenseeQuery);

            if (!licenseeSnapshot.empty) {
              const licenseeDoc = licenseeSnapshot.docs[0].data();
              const fullName = `${licenseeDoc.NAME || ''} ${licenseeDoc.SURNAME || ''}`.trim();
              licenseeData[user.referenceCode] = {
                name: fullName,
                email: licenseeDoc.EMAIL,
              };
            }
          }
        }
        setLicenseeDetails(licenseeData);
        setPendingUsers(pendingUsersList);
        setLoading(false);
      };

      fetchPendingUsers();
    }
  }, [userRole]);

  const approveUser = async (userId) => {
    try {
      const userRef = doc(db, 'users', userId);
      await updateDoc(userRef, { status: 'approved' });
      setPendingUsers(pendingUsers.filter(user => user.id !== userId));
    } catch (error) {
      console.error('Error approving user:', error);
    }
  };

  const denyUser = async (userId) => {
    try {
      const userRef = doc(db, 'users', userId);
      await updateDoc(userRef, { status: 'denied' });
      setPendingUsers(pendingUsers.filter(user => user.id !== userId));
    } catch (error) {
      console.error('Error denying user:', error);
    }
  };

  const handleOpenModal = () => setOpenModal(true);
  const handleCloseModal = () => setOpenModal(false);

  const bedData = {
    labels: ['Occupied Beds', 'Upcoming Empty Beds'],
    datasets: [
      {
        data: [totalTenants - movingOutCount, movingOutCount],
        backgroundColor: ['#36A2EB', '#FF6384'],
        hoverBackgroundColor: ['#36A2EB', '#FF6384'],
      },
    ],
  };

  if (userStatus === 'pending') {
    return (
      <Container>
        <Paper elevation={3} sx={{ p: 3 }}>
          <Typography variant="h4">Your Account is Pending Approval</Typography>
          <Typography variant="body1" sx={{ mt: 2 }}>
            Please wait for an administrator to approve your account.
          </Typography>
        </Paper>
      </Container>
    );
  }

  if (userRole === 'user') {
    return null;
  }

  return (
    <Container
      maxWidth="lg"
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh',
        flexDirection: 'column',
        gap: 3,
      }}
    >
      <Grid container spacing={3} justifyContent="center" alignItems="center">
        <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center' }}>
          <Box sx={{ width: { xs: '90%', md: '50%' } }}>
            <Pie data={bedData} />
          </Box>
        </Grid>

        {isMobile ? (
          <Grid item xs={12}>
            {/* Show only the count of pending users on mobile */}
            <Paper elevation={3} sx={{ p: 2, textAlign: 'center', width: '100%', maxWidth: '400px', margin: '0 auto' }}>
              <Typography variant="h6">
                {pendingUsers.length} Pending Users for Approval
              </Typography>
              {/* View List Button */}
              <Button
                variant="contained"
                color="primary"
                onClick={handleOpenModal}
                sx={{ mt: 2 }}
              >
                View List
              </Button>
            </Paper>
          </Grid>
        ) : (
          <Grid item xs={12} md={8}>
            <Paper elevation={3} sx={{ p: { xs: 2, md: 3 }, textAlign: 'center' }}>
              <Typography variant="h5">Pending Users for Approval</Typography>
              <Grid container spacing={2}>
                {pendingUsers.map((user) => {
                  const licensee = licenseeDetails[user.referenceCode];
                  const matchStatus = getMatchStatus(user, licensee);
                  return (
                    <Grid item xs={12} md={6} key={user.id}>
                      <Paper
                        elevation={2}
                        sx={{
                          p: 2,
                          backgroundColor:
                            matchStatus === 'green'
                              ? '#d4edda'
                              : matchStatus === 'orange'
                              ? '#fff3cd'
                              : '#f8d7da',
                        }}
                      >
                        <Typography variant="h6">{user.fullName}</Typography>
                        <Typography>Email: {user.email}</Typography>
                        <Typography>Reference Code: {user.referenceCode}</Typography>
                        {licensee && (
                          <>
                            <Typography>Licensee Name: {licensee.name}</Typography>
                            <Typography>Licensee Email: {licensee.email}</Typography>
                          </>
                        )}
                        <Box sx={{ mt: 2 }}>
                          <Button
                            variant="contained"
                            color="primary"
                            sx={{ fontSize: '0.875rem', mr: 2 }}
                            onClick={() => approveUser(user.id)}
                          >
                            Approve
                          </Button>
                          <Button
                            variant="contained"
                            color="error"
                            sx={{ fontSize: '0.875rem' }}
                            onClick={() => denyUser(user.id)}
                          >
                            Deny
                          </Button>
                        </Box>
                      </Paper>
                    </Grid>
                  );
                })}
              </Grid>
            </Paper>
          </Grid>
        )}
      </Grid>

      {/* Modal for viewing pending user list on mobile */}
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="pending-users-modal-title"
        aria-describedby="pending-users-modal-description"
      >
        <Box
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '90%',
            bgcolor: 'background.paper',
            borderRadius: 2,
            boxShadow: 24,
            p: 4,
            maxHeight: '80vh',
            overflowY: 'auto', // Allow scrolling for long lists
          }}
        >
          <Typography id="pending-users-modal-title" variant="h6" component="h2" textAlign="center">
            Pending Users for Approval
          </Typography>
          <Grid container spacing={2}>
            {pendingUsers.map((user) => {
              const licensee = licenseeDetails[user.referenceCode];
              const matchStatus = getMatchStatus(user, licensee);
              return (
                <Grid item xs={12} key={user.id}>
                  <Paper
                    elevation={2}
                    sx={{
                      p: 2,
                      backgroundColor:
                        matchStatus === 'green'
                          ? '#d4edda'
                          : matchStatus === 'orange'
                          ? '#fff3cd'
                          : '#f8d7da',
                    }}
                  >
                    <Typography variant="h6">{user.fullName}</Typography>
                    <Typography>Email: {user.email}</Typography>
                    <Typography>Reference Code: {user.referenceCode}</Typography>
                    {licensee && (
                      <>
                        <Typography>Licensee Name: {licensee.name}</Typography>
                        <Typography>Licensee Email: {licensee.email}</Typography>
                      </>
                    )}
                    <Box sx={{ mt: 2 }}>
                      <Button
                        variant="contained"
                        color="primary"
                        sx={{ fontSize: '0.875rem', mr: 2 }}
                        onClick={() => approveUser(user.id)}
                      >
                        Approve
                      </Button>
                      <Button
                        variant="contained"
                        color="error"
                        sx={{ fontSize: '0.875rem' }}
                        onClick={() => denyUser(user.id)}
                      >
                        Deny
                      </Button>
                    </Box>
                  </Paper>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Modal>
    </Container>
  );
}

export default Home;
