import React from 'react';
import { Container, Typography, Paper } from '@mui/material';

function GiveNotice() {
    return (
        <Container maxWidth="sm">
            <Paper elevation={3} sx={{ padding: 4, backgroundColor: 'background.paper' }}>
                <Typography variant="h1" gutterBottom>
                    Give Notice
                </Typography>
                <Typography variant="body1" gutterBottom>
                    We’ll be adding this feature after Christmas. Until then, please simply message one of our staff as usual if you wish to give notice.
                </Typography>
            </Paper>
        </Container>
    );
}

export default GiveNotice;
