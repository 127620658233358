import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import {
  Box,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Typography,
  Toolbar,
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';

function NavBar() {
  const { currentUser, logout, userRole } = useAuth();
  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleLogout = async () => {
    try {
      await logout();
    } catch (error) {
      console.error('Failed to log out', error);
    }
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
      <Typography variant="h6" sx={{ my: 2 }}>
        LOBI Web Portal
      </Typography>
      <List>
        <ListItem button component={Link} to="/" key="home">
          <ListItemText primary="Home" />
        </ListItem>

        {/* Admin Role */}
        {currentUser && userRole === 'admin' && (
          <>
            <ListItem button component={Link} to="/licensees" key="admin-licensees">
              <ListItemText primary="View Licensees" />
            </ListItem>
            <ListItem button component={Link} to="/add-licensee" key="admin-add-licensee">
              <ListItemText primary="Add Licensee" />
            </ListItem>

            <ListItem button component={Link} to="/dashboard" key="admin-dashboard">
              <ListItemText primary="Maintenance Dashboard" />
            </ListItem>

            <ListItem button component={Link} to="/generate-documents" key="admin-generate-documents">
              <ListItemText primary="Generate Documents" />
            </ListItem>

            <ListItem button component={Link} to="/admin-rent-management" key="admin-rent-management">
              <ListItemText primary="Rent Management" />
            </ListItem>
            <ListItem button component={Link} to="/moving-out-list" key="admin-moving-out-list">
              <ListItemText primary="Moving Out List" />
            </ListItem>
          </>
        )}

        {/* Viewings Role */}
        {currentUser && userRole === 'viewings' && (
          <>
            <Typography variant="subtitle1" sx={{ my: 1 }}>
              Licensee Management
            </Typography>
            <ListItem button component={Link} to="/licensees" key="viewings-licensees">
              <ListItemText primary="View Licensees" />
            </ListItem>
            <ListItem button component={Link} to="/add-licensee" key="viewings-add-licensee">
              <ListItemText primary="Add Licensee" />
            </ListItem>

            <Typography variant="subtitle1" sx={{ my: 1 }}>
              Document Management
            </Typography>
            <ListItem button component={Link} to="/generate-documents" key="viewings-generate-documents">
              <ListItemText primary="Generate Documents" />
            </ListItem>
          </>
        )}

        {/* Maintenance Role */}
        {currentUser && userRole === 'maintenance' && (
          <>
            <Typography variant="subtitle1" sx={{ my: 1 }}>
              Maintenance
            </Typography>
            <ListItem button component={Link} to="/dashboard" key="maintenance-dashboard">
              <ListItemText primary="Maintenance Dashboard" />
            </ListItem>
          </>
        )}

        {/* Regular User */}
        {currentUser && userRole === 'user' && (
          <>
            <ListItem button component={Link} to="/licensee-services" key="user-licensee-services">
              <ListItemText primary="Services" />
            </ListItem>
            <ListItem button component={Link} to="/house-rules" key="house-rules">
              <ListItemText primary="House Rules" />
            </ListItem>
          </>
        )}

        {/* Profile for all logged-in users */}
        {currentUser && (
          <ListItem button component={Link} to="/profile" key="profile">
            <ListItemText primary="Profile" />
          </ListItem>
        )}

        {/* Logout and Login/Register */}
        {currentUser && (
          <ListItem button onClick={handleLogout} key="logout">
            <ListItemText primary="Logout" />
          </ListItem>
        )}
        {!currentUser && (
          <>
            <ListItem button component={Link} to="/login" key="login">
              <ListItemText primary="Login" />
            </ListItem>
            <ListItem button component={Link} to="/register" key="register">
              <ListItemText primary="Register" />
            </ListItem>
          </>
        )}
      </List>
    </Box>
  );

  return (
    <>
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={handleDrawerToggle}
          sx={{ position: 'fixed', top: 20, left: 20 }}
        >
          <MenuIcon />
        </IconButton>
      </Toolbar>
      <Drawer
        anchor="left"  // Move drawer to the right
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: 240 },
        }}
      >
        {drawer}
      </Drawer>
    </>
  );
}

export default NavBar;
