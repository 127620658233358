import React, { useState, useEffect } from 'react';
import { Container, Typography, Box, Button, Paper, Alert, LinearProgress, Checkbox, FormControlLabel, Link, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { getAuth } from 'firebase/auth';
import { getStorage, ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';

const ReferenceUploader = () => {
  const [customerId, setCustomerId] = useState(null);
  const [referenceTypes] = useState(['Payslips', 'Landlord Reference', 'Employer Reference', 'Proof of Funds']);
  const [uploadedReferences, setUploadedReferences] = useState({});
  const [missingReferences, setMissingReferences] = useState(referenceTypes);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [gdprConsent, setGdprConsent] = useState(false);
  const [statusMessage, setStatusMessage] = useState('');
  const [error, setError] = useState('');
  const [gdprError, setGdprError] = useState('');
  const [applicationComplete, setApplicationComplete] = useState(false);
  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [consoleOutput, setConsoleOutput] = useState('');
  const navigate = useNavigate();

  const logToConsole = (message) => {
    setConsoleOutput((prev) => `${prev}\n${message}`);
    console.log(message);
  };

  useEffect(() => {
    const auth = getAuth();
    const user = auth.currentUser;

    if (user) {
      setCustomerId(user.uid);
      logToConsole(`Customer ID fetched: ${user.uid}`);
    } else {
      logToConsole("No authenticated user found.");
      setError('You need to be logged in to upload references.');
    }
  }, []);

  const handleFileUpload = async (referenceType, file) => {
    if (!customerId) {
      setError('No customer ID found. Please log in.');
      return;
    }

    setStatusMessage(`Uploading ${referenceType}...`);
    logToConsole(`Uploading file for ${referenceType}...`);
    
    try {
      const storage = getStorage();
      const storageRef = ref(storage, `references/${customerId}/${referenceType}/${file.name}`);

      const uploadTask = await uploadBytes(storageRef, file);

      const downloadURL = await getDownloadURL(uploadTask.ref);
      logToConsole(`File uploaded successfully for ${referenceType}. Download URL: ${downloadURL}`);

      setUploadedReferences((prev) => ({
        ...prev,
        [referenceType]: { fileName: file.name, downloadURL },
      }));

      setMissingReferences((prev) => prev.filter((ref) => ref !== referenceType));

      setStatusMessage(`${referenceType} uploaded successfully.`);
      await storeReferenceDetails(customerId, referenceType, file.name, downloadURL);
    } catch (error) {
      console.error('File upload error:', error);
      setError('Error uploading file.');
      setStatusMessage('Error uploading file.');
      logToConsole(`File upload error for ${referenceType}: ${error.message}`);
    }
  };

  const storeReferenceDetails = async (customerId, referenceType, fileName, downloadURL) => {
    logToConsole(`Storing ${referenceType} details in Firestore...`);
    logToConsole(`Customer ID: ${customerId}, Reference Type: ${referenceType}, File Name: ${fileName}, Download URL: ${downloadURL}`);
  
    if (!customerId || !downloadURL) {
      logToConsole('Error: customerId or downloadURL is missing.');
      setError('Customer ID or download URL is missing.');
      return;
    }
  
    try {
      const references = {
        [referenceType]: {
          fileName: fileName,
          downloadURL: downloadURL
        }
      };
  
      await axios.post(
        'https://europe-west1-lobi-portal.cloudfunctions.net/storeReferenceDetails',
        {
          customerId: customerId,
          references: references,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );
      logToConsole(`Stored ${referenceType} details in Firestore.`);
    } catch (error) {
      console.error('Error storing reference details:', error);
      logToConsole(`Error storing reference details for ${referenceType}: ${error.message}`);
      setError('Failed to store reference details.');
    }
  };

  const handleCompleteApplication = () => {
    if (missingReferences.length > 0) {
      setConfirmationOpen(true);
    } else {
      submitApplication();
    }
  };

  const submitApplication = async () => {
    if (!validateGdprConsent()) return;

    setConfirmationOpen(false);
    setStatusMessage('Submitting application...');
    logToConsole('Submitting application...');

    try {
      setApplicationComplete(true);
      setStatusMessage('Application submitted successfully!');
      logToConsole('Application submitted successfully.');

      navigate('/reference-success');
    } catch (error) {
      console.error('Error submitting application:', error.message);
      setError('Failed to submit application.');
      logToConsole(`Error submitting application: ${error.message}`);
    }
  };

  const validateGdprConsent = () => {
    if (!gdprConsent) {
      setGdprError('You must consent to our GDPR policy before proceeding.');
      logToConsole('GDPR consent not given');
      return false;
    }
    return true;
  };

  return (
    <Container maxWidth="sm">
      <Paper elevation={3} sx={{ p: 3, mt: 5 }}>
        <Typography variant="h5" gutterBottom>
          Upload Your References
        </Typography>
        {error && <Alert severity="error">{error}</Alert>}
        {statusMessage && <Alert severity="info">{statusMessage}</Alert>}

        <Box>
          {referenceTypes.map((referenceType, index) => (
            <Box key={index} sx={{ mb: 2 }}>
              <Typography variant="subtitle1">{referenceType}</Typography>
              <Button
                variant="contained"
                component="label"
                disabled={uploadedReferences[referenceType]?.fileName || !customerId}
              >
                {uploadedReferences[referenceType]?.fileName ? 'Uploaded' : `Upload ${referenceType}`}
                <input
                  type="file"
                  hidden
                  onChange={(e) => handleFileUpload(referenceType, e.target.files[0])}
                />
              </Button>
              {uploadedReferences[referenceType]?.fileName && (
                <Typography variant="body2" sx={{ mt: 1 }}>
                  Uploaded: {uploadedReferences[referenceType].fileName}
                </Typography>
              )}
              {uploadProgress > 0 && uploadProgress < 100 && (
                <LinearProgress variant="determinate" value={uploadProgress} />
              )}
            </Box>
          ))}
        </Box>

        <FormControlLabel
          control={
            <Checkbox
              checked={gdprConsent}
              onChange={(e) => setGdprConsent(e.target.checked)}
              name="gdprConsent"
              required
            />
          }
          label={
            <>
              I consent to the storage of my personal information in accordance with the{' '}
              <Link href="/privacy-policy" target="_blank" rel="noopener">
                GDPR Policy
              </Link>.
            </>
          }
        />
        {gdprError && <Alert severity="error">{gdprError}</Alert>}

        <Button
          variant="contained"
          color="primary"
          fullWidth
          sx={{ mt: 2 }}
          onClick={handleCompleteApplication}
          disabled={!customerId}
        >
          Complete Application
        </Button>

        <Box sx={{ mt: 3 }}>
          <Typography variant="subtitle1" gutterBottom>
            Debug Console Output:
          </Typography>
          <Alert severity="info" style={{ whiteSpace: 'pre-wrap' }}>
            {consoleOutput}
          </Alert>
        </Box>

        <Dialog open={confirmationOpen} onClose={() => setConfirmationOpen(false)}>
          <DialogTitle>Incomplete Application</DialogTitle>
          <DialogContent>
            <DialogContentText>
              It seems that some references are missing: {missingReferences.join(', ')}. Are you sure you want to submit the application with missing references?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setConfirmationOpen(false)} color="primary">
              Cancel
            </Button>
            <Button onClick={submitApplication} color="primary" autoFocus>
              Yes, Submit
            </Button>
          </DialogActions>
        </Dialog>

        {applicationComplete && (
          <Alert severity="success" sx={{ mt: 2 }}>
            Your application is complete! We will be in touch soon.
          </Alert>
        )}
      </Paper>
    </Container>
  );
};

export default ReferenceUploader;

