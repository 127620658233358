import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  Button,
  Box,
  LinearProgress,
  CircularProgress,
  Checkbox,
  FormControlLabel,
  TextField,
  IconButton,
} from '@mui/material';
import { useParams } from 'react-router-dom';
import { db, storage } from '../firebase';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { useAuth } from '../contexts/AuthContext';
import SubCategorySelection from './SubCategorySelection';
import DeleteIcon from '@mui/icons-material/Delete';
import { getDoc, addDoc, doc, collection, query, where, getDocs } from 'firebase/firestore';


function ReportDetail() {
  const { category } = useParams();
  const { currentUser } = useAuth();
  const [message, setMessage] = useState('');
  const [uploadStatus, setUploadStatus] = useState('idle');
  const [uploadProgress, setUploadProgress] = useState(0);
  const [property, setProperty] = useState('');
  const [loadingProperty, setLoadingProperty] = useState(true);
  const [watchedVideos, setWatchedVideos] = useState(false);
  const [files, setFiles] = useState([]);
  const [fileUrls, setFileUrls] = useState([]);
  const [fullName, setFullName] = useState('');
  const [referenceCode, setReferenceCode] = useState('');
  const [userRole, setUserRole] = useState('');
  const [issueDetails, setIssueDetails] = useState({
    category: category || '',
    subcategory: '',
    issue: '',
    customIssue: '',
  });

  useEffect(() => {
    const fetchUserProperty = async () => {
      if (currentUser) {
        try {
          const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
          if (userDoc.exists()) {
            const userData = userDoc.data();
            setUserRole(userData.role); // Set the user role
            const userReferenceCode = userData.referenceCode;
            setReferenceCode(userReferenceCode);
            setFullName(userData.fullName);
    
            if (userData.role !== 'maintenance' && userData.role !== 'admin') {
              const licenseeQuery = query(
              collection(db, 'licensees'),
              where('REFERENCE CODE', '==', userReferenceCode)
              );
              const licenseeSnapshot = await getDocs(licenseeQuery);
              if (!licenseeSnapshot.empty) {
              const licenseeData = licenseeSnapshot.docs[0].data();
              setProperty(licenseeData.ADDRESS || 'Unknown Property');
              }
            }
          }
        } catch (err) {
          console.error('Error fetching property:', err);
        } finally {
          setLoadingProperty(false);
        }
      }
    };
    
    

    fetchUserProperty();
  }, [currentUser]);

  const handleIssueDetailsChange = (details) => {
    setIssueDetails(details);
  };

  const handleFileChange = (e) => {
    const selectedFiles = Array.from(e.target.files);

    if (selectedFiles.length + files.length > 10) {
      setMessage('You can only upload up to 10 media items.');
      return;
    }

    setFiles((prevFiles) => [...prevFiles, ...selectedFiles]);
    selectedFiles.forEach(uploadFile); // Automatically start uploading each file
  };

  const uploadFile = (file) => {
    const fileRef = ref(storage, `maintenance/${Date.now()}-${file.name}`);
    const uploadTask = uploadBytesResumable(fileRef, file);

    setUploadStatus('uploading');

    uploadTask.on(
      'state_changed',
      (snapshot) => {
        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        setUploadProgress(progress);
      },
      (error) => {
        console.error('Error uploading media:', error);
        setUploadStatus('error');
        setMessage('Error uploading media. Please try again.');
      },
      async () => {
        const url = await getDownloadURL(uploadTask.snapshot.ref);
        setFileUrls((prevUrls) => [...prevUrls, url]);
        setUploadStatus('success');
        setMessage('Media uploaded successfully!');
      }
    );
  };

  const handleRemoveFile = (index) => {
    setFiles(files.filter((_, i) => i !== index));
    setFileUrls(fileUrls.filter((_, i) => i !== index));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!currentUser) {
      setMessage('You must be logged in to report an issue.');
      return;
    }
    if (!property) {
      setMessage('Property information is missing.');
      return;
    }
    // if (!watchedVideos) {
    //   setMessage('You must confirm you have watched the instructional videos before reporting the issue.');
    //   return;
    // }
    if (files.length === 0) {
      setMessage('You must upload a photo or video of the problem.');
      return;
    }
    if (uploadStatus !== 'success') {
      setMessage('Please wait for the upload to finish before submitting.');
      return;
    }
    if (!issueDetails.category || !issueDetails.subcategory || !issueDetails.issue) {
      setMessage('Please select the category, subcategory, and issue.');
      return;
    }
    if ((issueDetails.issue === 'Other' || issueDetails.subcategory === 'Other') && !issueDetails.customIssue) {
      setMessage('Please describe the issue.');
      return;
    }

    try {
      const maintenanceDoc = {
        issueType: issueDetails.category,
        subcategory: issueDetails.subcategory,
        issue: issueDetails.issue,
        description: issueDetails.customIssue,
        property,
        location: property,
        status: 'Reported',
        createdAt: new Date(),
        userId: currentUser.uid,
        referenceCode,
        fileUrls,
      };

      await addDoc(collection(db, 'maintenance'), maintenanceDoc);

      setMessage('Issue reported successfully!');
      setFiles([]);
      setUploadProgress(0);
      setFileUrls([]);
      setIssueDetails({
        category: category || '',
        subcategory: '',
        issue: '',
        customIssue: '',
      });
    } catch (error) {
      console.error('Error reporting the issue:', error);
      setMessage('Failed to report the issue. Please try again.');
    }
  };

  return (
    <Container maxWidth="sm">
      <Box
        component="form"
        onSubmit={handleSubmit}
        sx={{
          p: 3,
          backgroundColor: 'background.paper',
          borderRadius: 2,
          boxShadow: 3,
          mt: 4,
        }}
      >
        <Typography variant="h4" align="center" gutterBottom>
          Report {category} Issue
        </Typography>


        {loadingProperty ? (
        <CircularProgress />
          ) : (
            <Typography variant="h6" align="center">
              Reporting for: {fullName} (Ref Code: {referenceCode}) <br />
              {(userRole === 'admin' || userRole === 'maintenance') ? (
                <TextField
                  label="Enter Property Address"
                  value={property}
                  onChange={(e) => setProperty(e.target.value)}
                  fullWidth
                  margin="normal"
                />
              ) : (
                `Property: ${property}`
              )}
            </Typography>
          )}


        {/* Include SubCategorySelection */}
        <SubCategorySelection
          defaultCategory={category}
          onChange={handleIssueDetailsChange}
        />

        {/* File upload */}
        <Button variant="contained" component="label" fullWidth sx={{ mt: 2 }}>
          Select Photos/Videos
          <input
            type="file"
            hidden
            multiple
            onChange={handleFileChange}
            accept="image/*,video/*"
          />
        </Button>

        {uploadStatus === 'uploading' && (
          <Box sx={{ mt: 2 }}>
            <LinearProgress variant="determinate" value={uploadProgress} />
            <Typography variant="body2" align="center" sx={{ mt: 1 }}>
              Uploading... {Math.round(uploadProgress)}%
            </Typography>
          </Box>
        )}

        {uploadStatus === 'success' && (
          <Typography variant="body2" align="center" sx={{ mt: 2, color: 'green' }}>
            Media uploaded successfully!
          </Typography>
        )}

        {uploadStatus === 'error' && (
          <Typography variant="body2" align="center" sx={{ mt: 2, color: 'red' }}>
            Error uploading media. Please try again.
          </Typography>
        )}

        {/* Display selected files */}
        <Box sx={{ mt: 2 }}>
          {files.length > 0 && (
            <Box sx={{ display: 'flex', flexWrap: 'wrap' }}>
              {files.map((file, index) => (
                <Box key={index} sx={{ position: 'relative', marginRight: 1, marginBottom: 1 }}>
                  {file.type.startsWith('image/') ? (
                    <img
                      src={URL.createObjectURL(file)}
                      alt={`Preview ${file.name}`}
                      style={{ width: 100, height: 100, objectFit: 'cover' }}
                    />
                  ) : (
                    <video
                      src={URL.createObjectURL(file)}
                      controls
                      style={{ width: 100, height: 100, objectFit: 'cover' }}
                    />
                  )}
                  <IconButton
                    sx={{
                      position: 'absolute',
                      top: 0,
                      right: 0,
                      backgroundColor: 'rgba(0, 0, 0, 0.5)',
                      color: 'white',
                    }}
                    onClick={() => handleRemoveFile(index)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </Box>
              ))}
            </Box>
          )}
        </Box>

        {/* Confirmation Checkbox */}
        {/* <FormControlLabel
          control={
            <Checkbox
              checked={watchedVideos}
              onChange={(e) => setWatchedVideos(e.target.checked)}
            />
          }
          label="I have watched the instructional videos."
        /> */}

        <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 2 }}>
          Submit
        </Button>

        {message && (
          <Typography variant="body1" align="center" sx={{ mt: 2, color: 'text.primary' }}>
            {message}
          </Typography>
        )}
      </Box>
    </Container>
  );
}

export default ReportDetail;
