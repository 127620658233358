import React, { useState, useEffect } from 'react';
import { getStorage, ref, getDownloadURL, listAll } from "firebase/storage";
import Papa from "papaparse";
import { doc, getDoc } from 'firebase/firestore';
import { useAuth } from '../contexts/AuthContext';
import { db } from '../firebase';
import { Container, Typography, Button, CircularProgress, Checkbox, FormControlLabel, Link, Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';

function PayRent() {
    const { currentUser } = useAuth();
    const [rentInfo, setRentInfo] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [confirmPayment, setConfirmPayment] = useState(false);
    const [paymentPeriod, setPaymentPeriod] = useState('');
    const [region, setRegion] = useState("EU");
    const navigate = useNavigate();

    const capitalizeFirstLetter = (string) => {
        return string ? string.charAt(0).toUpperCase() + string.slice(1).toLowerCase() : '';
    };

    const extractPaymentPeriod = (fileName) => {
        const [month, day] = fileName.replace('_PAYMENT_LINKS.csv', '').split('_');
        return `${capitalizeFirstLetter(month.toLowerCase())} ${day}`;
    };

    const parseCSVAndFindUser = (csvText, referenceCode) => {
        return new Promise((resolve, reject) => {
            Papa.parse(csvText, {
                header: true,
                skipEmptyLines: true,
                complete: (result) => {
                    const userRentData = result.data.find(row => row['Ref Code'] === referenceCode);
                    resolve(userRentData || null);
                },
                error: (error) => reject(error),
            });
        });
    };

    useEffect(() => {
        const fetchRentData = async () => {
            if (!currentUser) return;

            try {
                const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
                if (userDoc.exists()) {
                    const referenceCode = userDoc.data().referenceCode;
                    console.log("User reference code:", referenceCode);

                    const storage = getStorage();
                    const baseRef = ref(storage, 'Open Banking Tracking/');
                    const yearFolders = await listAll(baseRef);
                    const latestYear = yearFolders.prefixes.pop().name;
                    console.log("Latest year:", latestYear);

                    const monthFolders = await listAll(ref(storage, `Open Banking Tracking/${latestYear}`));
                    const validMonthNames = monthFolders.prefixes.map(folder => folder.name).filter(Boolean);

                    console.log("Valid months:", validMonthNames);

                    const today = new Date();
                    const currentMonth = today.toLocaleString('default', { month: 'long' });
                    const currentDay = today.getDate();

                    const paymentDates = currentDay > 15 ? ["15TH", "1ST"] : ["1ST", "15TH"];
                    let userRentData = null;

                    const sortedMonthNames = validMonthNames.slice().sort((a, b) => {
                        const monthOrder = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
                        return monthOrder.indexOf(b) - monthOrder.indexOf(a);
                    });

                    for (let month of sortedMonthNames) {
                        console.log(`Checking month: ${capitalizeFirstLetter(month)}`);

                        for (let date of paymentDates) {
                            const fileRef = ref(storage, `Open Banking Tracking/${latestYear}/${capitalizeFirstLetter(month)}/${month.toUpperCase()}_${date}_PAYMENT_LINKS.csv`);
                            console.log(`Trying file: ${fileRef.fullPath}`);
                            
                            try {
                                const fileUrl = await getDownloadURL(fileRef);
                                const response = await fetch(fileUrl);
                                const csvText = await response.text();
                                userRentData = await parseCSVAndFindUser(csvText, referenceCode);

                                if (userRentData) {
                                    console.log(`Found user data in file: ${fileRef.fullPath}`);
                                    setRentInfo(userRentData);
                                    setPaymentPeriod(extractPaymentPeriod(fileRef.name));
                                    return;
                                } else {
                                    console.log(`User not found in file: ${fileRef.fullPath}`);
                                }
                            } catch (error) {
                                console.log(`File not found or error fetching: ${fileRef.fullPath}`, error);
                                continue;
                            }
                        }
                    }

                    if (!userRentData) {
                        setError("No rent data found in the latest rent periods.");
                    }
                } else {
                    setError("User data not found.");
                }
            } catch (error) {
                setError("Error fetching rent data.");
                console.error(error);
            } finally {
                setLoading(false);
            }
        };

        fetchRentData();
    }, [currentUser]);

    const handleConfirmChange = (event) => setConfirmPayment(event.target.checked);

    const handleConfirmPayment = () => {
        const paymentLinkCode = rentInfo['Payment Link'].split('/').pop();
        const userReference = `${rentInfo['Ref Code']}_${paymentLinkCode}`;
        window.open(rentInfo['Payment Link'], '_blank');
        navigate('/payment-confirmation', {
            state: { rentAmount: rentInfo['Rent'], paymentReference: userReference }
        });
    };

    const termsOfServiceLink = () => {
        switch (region) {
            case "EU": return "https://truelayer.com/en-ie/enduser_tos";
            case "EU-DE": return "https://truelayer.com/de-de/rechtliche-hinweise/nutzungsbedingungen-fur-endbenutzer";
            case "EU-ES": return "https://truelayer.com/es-es/legal/condiciones-del-servicio-para-el-usuario-final/";
            case "EU-FR": return "https://truelayer.com/fr-fr/legal/conditions-generales-dutilisation/";
            default: return "https://truelayer.com/enduser_tos";
        }
    };

    const privacyPolicyLink = () => {
        switch (region) {
            case "EU": return "https://truelayer.com/en-ie/privacy";
            case "EU-DE": return "https://truelayer.com/de-de/rechtliche-hinweise/datenschutzerklarung/";
            case "EU-ES": return "https://truelayer.com/es-es/legal/aviso-de-privacidad/";
            case "EU-FR": return "https://truelayer.com/fr-fr/legal/politique-de-confidentialite/";
            default: return "https://truelayer.com/privacy";
        }
    };

    if (loading) {
        return <CircularProgress />;
    }

    if (error) {
        return <Typography color="error">{error}</Typography>;
    }

    if (!rentInfo) {
        return <Typography>No rent information available.</Typography>;
    }

    return (
        <Container maxWidth="sm">
            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="flex-start" sx={{ paddingTop: '50px', paddingBottom: '50px' }}>
                <Typography variant="h4" gutterBottom>Pay Rent For: {paymentPeriod}</Typography>
                <Typography variant="body1" gutterBottom>
                    You are paying {rentInfo.PayeeName || "Linehan O'Brien Investments Ltd"}.
                </Typography>
                <Typography variant="body1" gutterBottom>Amount: €{rentInfo['Rent']} EUR</Typography>
                <FormControlLabel
                    control={<Checkbox checked={confirmPayment} onChange={handleConfirmChange} />}
                    label="I confirm that I want to proceed with this payment."
                    sx={{ marginTop: '20px' }}
                />
                <Button 
                    variant="contained" 
                    color="primary" 
                    onClick={handleConfirmPayment}
                    disabled={!confirmPayment}
                    sx={{ marginTop: '20px' }}
                >
                    Confirm and Pay
                </Button>
                <Typography variant="body2" align="center" sx={{ marginTop: '30px', padding: '0 10px' }}>
                    By continuing you are permitting TrueLayer to initiate a payment from your bank account. 
                    You also agree to our{' '}
                    <Link href={termsOfServiceLink()} target="_blank" rel="noopener noreferrer">Terms of Service</Link> and{' '}
                    <Link href={privacyPolicyLink()} target="_blank" rel="noopener noreferrer">Privacy Policy</Link>.
                </Typography>
            </Box>
        </Container>
    );
}

export default PayRent;
