import React, { useState, useEffect, useCallback } from 'react';
import { getStorage, ref, getDownloadURL, listAll } from "firebase/storage";
import Papa from "papaparse";
import { db } from "../firebase";
import { collection, getDocs } from "firebase/firestore";
import { Box, Typography, Table, TableBody, TableCell, TableHead, TableRow, Accordion, AccordionSummary, AccordionDetails, TextField, MenuItem, FormControl, Select, InputLabel, Button, ButtonGroup, Snackbar, Alert, Card, CardContent, CardActions } from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { getFunctions, httpsCallable } from "firebase/functions"; // Import Firebase Functions
import './AdminRentPage.css';  // Import the CSS file

// Function to send reminder emails using Firebase callable function
const sendReminderEmail = async (licensee, setSnackbarState) => {
  try {
    const functions = getFunctions(undefined, 'europe-west1');// Get Firebase Functions instance
    const sendReminderEmailCallable = httpsCallable(functions, 'sendReminderEmail'); // Call the Firebase function
    
    const response = await sendReminderEmailCallable({
      email: licensee.EMAIL,
      subject: "Rent Reminder",
      message: `Dear ${licensee.NAME},\n\nThis is a friendly reminder that your rent is due. Please make your payment as soon as possible.\n\nRegards,\nThe Team`,
    });

    console.log("Email sent successfully:", response.data);
    setSnackbarState({ open: true, message: `Reminder sent to ${licensee.EMAIL}`, severity: "success" });
  } catch (error) {
    console.error("Error sending email:", error);
    setSnackbarState({ open: true, message: `Failed to send email to ${licensee.EMAIL}`, severity: "error" });
  }
};

// Function to fetch available periods from Firebase
const fetchAvailablePeriods = async () => {
  const storage = getStorage();
  const baseRef = ref(storage, 'Open Banking Tracking/');
  const availablePeriods = {};

  try {
    const yearFolders = await listAll(baseRef);

    for (const yearFolder of yearFolders.prefixes) {
      const year = yearFolder.name;
      if (/^\d{4}$/.test(year)) {
        availablePeriods[year] = {};

        const monthFolders = await listAll(ref(storage, `Open Banking Tracking/${year}`));
        for (const monthFolder of monthFolders.prefixes) {
          const month = monthFolder.name;
          availablePeriods[year][month] = [];

          const periodFiles = await listAll(ref(storage, `Open Banking Tracking/${year}/${month}`));
          periodFiles.items.forEach(file => {
            const period = file.name.replace('.csv', '').split('_')[1];
            availablePeriods[year][month].push(period);
          });
        }
      }
    }
  } catch (error) {
    console.error("Error fetching available periods from Firebase", error);
  }

  return availablePeriods;
};

// Function to check if the rent has been paid
const hasPaidRent = (licensee, paymentData) => {
  const matchingPayment = paymentData.find(payment => payment['Ref Code'] === licensee['REFERENCE CODE']);
  return matchingPayment && matchingPayment.succeeded === 'succeeded';
};

// Main AdminRentPage Component
const AdminRentPage = () => {
  const [licensees, setLicensees] = useState({});
  const [filteredLicensees, setFilteredLicensees] = useState({});
  const [paymentData, setPaymentData] = useState([]);
  const [selectedYear, setSelectedYear] = useState('');
  const [selectedMonth, setSelectedMonth] = useState('');
  const [selectedPeriod, setSelectedPeriod] = useState('');
  const [availablePeriods, setAvailablePeriods] = useState({});
  const [availablePropertyCodes, setAvailablePropertyCodes] = useState([]);
  const [loadingPeriods, setLoadingPeriods] = useState(true);
  const [expandedHouse, setExpandedHouse] = useState(null);
  const [totalDue, setTotalDue] = useState(0);
  const [totalReceived, setTotalReceived] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [filter, setFilter] = useState('all');
  const [snackbarState, setSnackbarState] = useState({ open: false, message: '', severity: '' });
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768); // Added to track mobile view

  // Close the Snackbar
  const handleSnackbarClose = () => {
    setSnackbarState({ ...snackbarState, open: false });
  };

  // Fetch houses/tenants on load
  useEffect(() => {
    const fetchHouseList = async () => {
      try {
        const licenseeCollection = collection(db, 'licensees');
        const licenseeSnapshot = await getDocs(licenseeCollection);
        const propertyCodes = [...new Set(licenseeSnapshot.docs.map(doc => extractPropertyCode(doc.data()['REFERENCE CODE'])))];
        setAvailablePropertyCodes(propertyCodes);

        const houseList = licenseeSnapshot.docs.reduce((acc, doc) => {
          const propertyCode = extractPropertyCode(doc.data()['REFERENCE CODE']);
          if (!acc[propertyCode]) acc[propertyCode] = [];
          acc[propertyCode].push({ id: doc.id, ...doc.data() });
          return acc;
        }, {});
        setLicensees(houseList);
        setFilteredLicensees(houseList);
      } catch (error) {
        console.error("Error fetching house list from Firestore:", error);
      }
    };

    fetchHouseList();
  }, []);

  const getCurrentPaymentRun = useCallback(() => {
    const now = new Date();
    const day = now.getDate();
    return day >= 13 && day <= 27 ? "15th" : "1st";
  }, []);

  // Fetch available periods on component load
useEffect(() => {
    const fetchPeriods = async () => {
      setLoadingPeriods(true);
      const periods = await fetchAvailablePeriods();
      setAvailablePeriods(periods);

      console.log("Available periods fetched:", periods);

      // Sort years and months in descending order to get the most recent one
      const sortedYears = Object.keys(periods).sort((a, b) => b - a);
      const recentYear = sortedYears[0]; // Most recent year
      console.log("Most recent year:", recentYear);

      if (recentYear) {
        const sortedMonths = Object.keys(periods[recentYear]).sort((a, b) => {
          const monthOrder = { "January": 1, "February": 2, "March": 3, "April": 4, "May": 5, "June": 6, "July": 7, "August": 8, "September": 9, "October": 10, "November": 11, "December": 12 };
          return monthOrder[b] - monthOrder[a];
        });
        const recentMonth = sortedMonths[0]; // Most recent month
        console.log("Most recent month:", recentMonth);

        if (recentMonth && periods[recentYear][recentMonth].length > 0) {
          const recentPeriod = getCurrentPaymentRun(); // "1st" or "15th"
          console.log("Current payment run based on date:", recentPeriod);

          setSelectedYear(recentYear);
          setSelectedMonth(recentMonth);
          setSelectedPeriod(recentPeriod);

          console.log("Selected year:", recentYear);
          console.log("Selected month:", recentMonth);
          console.log("Selected period:", recentPeriod);

          fetchCSVFromFirebase(recentYear, recentMonth, recentPeriod);
        } else {
          console.warn("No periods found for the most recent month:", recentMonth);
        }
      } else {
        console.warn("No recent year found in available periods.");
      }
      setLoadingPeriods(false);
    };

    fetchPeriods();
  }, [getCurrentPaymentRun]);

  

  // Handle screen resizing to update mobile state
  useEffect(() => {
    const handleResize = () => setIsMobile(window.innerWidth <= 768);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // Fetch CSV data when year, month, or period changes
  useEffect(() => {
    if (selectedYear && selectedMonth && selectedPeriod) {
      fetchCSVFromFirebase(selectedYear, selectedMonth, selectedPeriod);
    }
  }, [selectedYear, selectedMonth, selectedPeriod]);

  const fetchCSVFromFirebase = async (year, month, period) => {
    const storage = getStorage();
    const folderRef = ref(storage, `Open Banking Tracking/${year}/${month}`);

    try {
      const periodFiles = await listAll(folderRef);
      const matchingFile = periodFiles.items.find(item => item.name.includes(`_${period}.csv`));

      if (matchingFile) {
        const url = await getDownloadURL(matchingFile);
        const response = await fetch(url);
        const csvText = await response.text();

        Papa.parse(csvText, {
          header: true,
          skipEmptyLines: true,
          complete: (result) => {
            if (result.data.length > 0) {
              calculateTotals(result.data);
              setPaymentData(result.data);
            }
          },
          error: (error) => {
            console.error("Error parsing CSV:", error.message);
          }
        });
      }
    } catch (error) {
      console.error("Failed to fetch CSV from Firebase", error);
    }
  };

  const calculateTotals = (parsedData) => {
    let rentDue = 0;
    let rentReceived = 0;

    parsedData.forEach(row => {
      const rent = parseFloat(row['Rent']) || 0;
      rentDue += rent;
      if (row['succeeded'] === "succeeded") rentReceived += rent;
    });

    setTotalDue(rentDue);
    setTotalReceived(rentReceived);
  };

  const extractPropertyCode = (referenceCode) => {
    return referenceCode.replace(/\d+$/, '');
  };

  useEffect(() => {
    if (searchTerm === '' && filter === 'all') {
      setFilteredLicensees(licensees);
    } else {
      const filtered = Object.keys(licensees).reduce((acc, propertyCode) => {
        const tenants = Array.isArray(licensees[propertyCode]) ? licensees[propertyCode].filter(
          tenant => {
            const matchesSearchTerm = tenant['REFERENCE CODE'].toLowerCase().includes(searchTerm.toLowerCase()) ||
                                      tenant.NAME.toLowerCase().includes(searchTerm.toLowerCase()) ||
                                      tenant.SURNAME.toLowerCase().includes(searchTerm.toLowerCase());
            const matchesFilter = (filter === 'all') ||
                                  (filter === 'paid' && hasPaidRent(tenant, paymentData)) ||
                                  (filter === 'unpaid' && !hasPaidRent(tenant, paymentData));
            return matchesSearchTerm && matchesFilter;
          }
        ) : [];
        if (tenants.length > 0) acc[propertyCode] = tenants;
        return acc;
      }, {});
      setFilteredLicensees(filtered);
    }
  }, [searchTerm, filter, licensees, paymentData]);

  const handleHouseExpand = async (propertyCode) => {
    if (!licensees[propertyCode]) {
      await fetchTenantsForHouse(propertyCode);
    }
    setExpandedHouse(propertyCode === expandedHouse ? null : propertyCode);
  };

  // Fetch tenants for a specific house
  const fetchTenantsForHouse = async (propertyCode) => {
    try {
      const licenseeCollection = collection(db, 'licensees');
      const licenseeSnapshot = await getDocs(licenseeCollection);

      const tenantsForHouse = licenseeSnapshot.docs
        .map(doc => ({ id: doc.id, ...doc.data() }))
        .filter(tenant => extractPropertyCode(tenant['REFERENCE CODE']) === propertyCode);

      setLicensees(prevState => ({ ...prevState, [propertyCode]: tenantsForHouse }));
    } catch (error) {
      console.error("Error fetching tenants for house:", error);
    }
  };

  if (isMobile) {
    return (
      <div>
        <h1>Admin Rent Management</h1>

        <Box mb={3}>
          <TextField
            label="Search Licensees or Properties"
            variant="outlined"
            fullWidth
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </Box>

        {/* Show simplified card-based layout for mobile */}
        {Object.keys(filteredLicensees).length === 0 ? (
          <Typography>No houses found matching your search criteria.</Typography>
        ) : (
          availablePropertyCodes.map(propertyCode => (
            filteredLicensees[propertyCode] && (
              <Box key={propertyCode} mb={3}>
                <Typography variant="h6">{propertyCode}</Typography>
                {filteredLicensees[propertyCode].map(tenant => (
                  <Card key={tenant.id} sx={{ marginBottom: 2 }}>
                    <CardContent>
                      <Typography>Reference Code: {tenant['REFERENCE CODE']}</Typography>
                      <Typography>Rent Status: {hasPaidRent(tenant, paymentData) ? 'Paid' : 'Unpaid'}</Typography>
                    </CardContent>
                    <CardActions>
                      <Button
                        variant="outlined"
                        size="small"
                        color="primary"
                      >
                        View
                      </Button>
                      {!hasPaidRent(tenant, paymentData) && (
                        <Button
                          variant="outlined"
                          size="small"
                          color="secondary"
                          onClick={() => sendReminderEmail(tenant, setSnackbarState)}
                        >
                          Send Reminder
                        </Button>
                      )}
                    </CardActions>
                  </Card>
                ))}
              </Box>
            )
          ))
        )}

        {/* Snackbar for Success/Error Notifications */}
        <Snackbar
          open={snackbarState.open}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
          sx={{ 
            '& .MuiSnackbarContent-root': {
              fontSize: '1.5rem', // Increase font size
              padding: '16px 24px', // Increase padding
            }
          }}
        >
          <Alert onClose={handleSnackbarClose} severity={snackbarState.severity} sx={{ width: '100%' }}>
            {snackbarState.message}
          </Alert>
        </Snackbar>
      </div>
    );
  }

  return (
    <div>
      <h1>Admin Rent Management</h1>

      {/* Desktop view */}
      <Box mb={3}>
        <TextField
          label="Search Licensees or Properties"
          variant="outlined"
          fullWidth
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </Box>

      <Box mb={3}>
        <ButtonGroup variant="outlined">
          <Button
            variant={filter === 'all' ? 'contained' : 'outlined'}
            onClick={() => setFilter('all')}
          >
            Show All
          </Button>
          <Button
            variant={filter === 'paid' ? 'contained' : 'outlined'}
            onClick={() => setFilter('paid')}
          >
            Show Paid
          </Button>
          <Button
            variant={filter === 'unpaid' ? 'contained' : 'outlined'}
            onClick={() => setFilter('unpaid')}
          >
            Show Unpaid
          </Button>
        </ButtonGroup>
      </Box>

      {/* Date Selectors */}
      <Box mb={3}>
        <FormControl fullWidth>
          <InputLabel id="year-select-label" sx={{ fontSize: '16px', paddingLeft: '5px' }}>Year</InputLabel>
          <Select
            labelId="year-select-label"
            value={selectedYear}
            onChange={(e) => setSelectedYear(e.target.value)}
            sx={{ padding: '8px', marginTop: '8px' }}
          >
            {Object.keys(availablePeriods).map(year => (
              <MenuItem key={year} value={year}>
                {year}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth sx={{ mt: 2 }}>
          <InputLabel id="month-select-label" sx={{ fontSize: '16px', paddingLeft: '5px' }}>Month</InputLabel>
          <Select
            labelId="month-select-label"
            value={selectedMonth}
            onChange={(e) => setSelectedMonth(e.target.value)}
            disabled={!selectedYear}
            sx={{ padding: '8px', marginTop: '8px' }}
          >
            {selectedYear && Object.keys(availablePeriods[selectedYear]).map(month => (
              <MenuItem key={month} value={month}>
                {month}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        <FormControl fullWidth sx={{ mt: 2 }}>
          <InputLabel id="period-select-label" sx={{ fontSize: '16px', paddingLeft: '5px' }}>Rent Period</InputLabel>
          <Select
            labelId="period-select-label"
            value={selectedPeriod}
            onChange={(e) => setSelectedPeriod(e.target.value)}
            disabled={!selectedMonth || loadingPeriods || !availablePeriods[selectedYear]?.[selectedMonth]?.length}
            sx={{ padding: '8px', marginTop: '8px' }}
          >
            {Array.isArray(availablePeriods[selectedYear]?.[selectedMonth]) &&
              availablePeriods[selectedYear][selectedMonth].map(period => (
                <MenuItem key={period} value={period}>
                  {period}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      </Box>

      {/* Totals */}
      <Box mt={3}>
        <Typography variant="h6">Total Rent Due: €{totalDue.toFixed(2)}</Typography>
        <Typography variant="h6">Total Rent Received: €{totalReceived.toFixed(2)}</Typography>
        <Typography variant="h6">Total Rent Still Due: €{(totalDue - totalReceived).toFixed(2)}</Typography>
      </Box>

      {/* Licensees by Property */}
      {Object.keys(filteredLicensees).length === 0 ? (
        <Typography>No houses found matching your search criteria.</Typography>
      ) : (
        availablePropertyCodes.map(propertyCode => (
          filteredLicensees[propertyCode] && (
            <Accordion key={propertyCode} expanded={expandedHouse === propertyCode} onChange={() => handleHouseExpand(propertyCode)}>
              <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`${propertyCode}-content`} id={`${propertyCode}-header`}>
                <Typography variant="h6" style={{ fontWeight: 'bold' }}>{propertyCode}</Typography>
              </AccordionSummary>
              <AccordionDetails>
                {Array.isArray(filteredLicensees[propertyCode]) && filteredLicensees[propertyCode].length > 0 ? (
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Reference Code</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Email</TableCell>
                        <TableCell>Rent</TableCell>
                        <TableCell>Rent Status</TableCell>
                        <TableCell>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {filteredLicensees[propertyCode].map((tenant) => (
                        <TableRow key={tenant.id}>
                          <TableCell>{tenant['REFERENCE CODE']}</TableCell>
                          <TableCell>{tenant.NAME} {tenant.SURNAME}</TableCell>
                          <TableCell>{tenant.EMAIL}</TableCell>
                          <TableCell>{tenant.RENT ? `€${tenant.RENT}` : 'N/A'}</TableCell>
                          <TableCell>{hasPaidRent(tenant, paymentData) ? 'Paid' : 'Unpaid'}</TableCell>
                          <TableCell>
                            {!hasPaidRent(tenant, paymentData) && (
                              <Button 
                                variant="outlined" 
                                color="secondary" 
                                onClick={() => sendReminderEmail(tenant, setSnackbarState)}
                              >
                                Send Reminder
                              </Button>
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                ) : (
                  <Typography>No tenants found for this house.</Typography>
                )}
              </AccordionDetails>
            </Accordion>
          )
        ))
      )}

      {/* Snackbar for Success/Error Notifications */}
      <Snackbar
        open={snackbarState.open}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'center', horizontal: 'center' }}
        sx={{ 
          '& .MuiSnackbarContent-root': {
            fontSize: '1.5rem', // Increase font size
            padding: '16px 24px', // Increase padding
          }
        }}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarState.severity} sx={{ width: '100%' }}>
          {snackbarState.message}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default AdminRentPage;
