import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { db } from '../firebase';
import { doc, onSnapshot, updateDoc } from 'firebase/firestore';
import { GoogleMap, Marker, DirectionsRenderer, LoadScriptNext } from '@react-google-maps/api';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

function TrackLocation() {
  const { issueId } = useParams();
  const [location, setLocation] = useState(null);
  const [loading, setLoading] = useState(true);
  const [mapsLoaded, setMapsLoaded] = useState(false);
  const [directionsResponse, setDirectionsResponse] = useState(null);
  const [mapsApiLoaded, setMapsApiLoaded] = useState(false);
  const [isTrackingActive, setIsTrackingActive] = useState(false);
  const [remainingTime, setRemainingTime] = useState(null); // Track remaining time for tracking
  const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

  useEffect(() => {
    const issueRef = doc(db, 'maintenance', issueId);

    const unsubscribe = onSnapshot(issueRef, (docSnapshot) => {
      if (docSnapshot.exists()) {
        const data = docSnapshot.data();
        setLocation(data.locationTracking); // Maintenance person location
        setIsTrackingActive(data.isTrackingActive || false);
        if (data.trackingExpirationTime) {
          const expirationTime = data.trackingExpirationTime.toDate();
          const now = new Date();
          const timeDiff = expirationTime - now;

          if (timeDiff > 0) {
            setRemainingTime(timeDiff);
          } else {
            // Tracking should be inactive if the time has passed
            setIsTrackingActive(false);
            stopTracking(); // Auto stop tracking
          }

          if (mapsApiLoaded) {
            calculateRoute(data.locationTracking, data.location); // Call only if API is ready
          }
        }
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, [issueId, mapsApiLoaded]);

  const stopTracking = async () => {
    const issueRef = doc(db, 'maintenance', issueId);
    await updateDoc(issueRef, { isTrackingActive: false });
  };

  const handleMapsLoaded = () => {
    setMapsLoaded(true);
    setMapsApiLoaded(true); // Indicate that the API is ready
  };

  const calculateRoute = (origin, destinationAddress) => {
    if (!window.google || !window.google.maps) return;

    const directionsService = new window.google.maps.DirectionsService();
    directionsService.route(
      {
        origin: { lat: origin.lat, lng: origin.lng },
        destination: destinationAddress,
        travelMode: window.google.maps.TravelMode.DRIVING,
      },
      (result, status) => {
        if (status === window.google.maps.DirectionsStatus.OK) {
          setDirectionsResponse(result);
        }
      }
    );
  };

  useEffect(() => {
    if (remainingTime !== null) {
      const interval = setInterval(() => {
        const newRemainingTime = remainingTime - 1000;
        if (newRemainingTime <= 0) {
          clearInterval(interval);
          setIsTrackingActive(false);
          stopTracking(); // Automatically stop tracking
        } else {
          setRemainingTime(newRemainingTime);
        }
      }, 1000);
      return () => clearInterval(interval);
    }
  }, [remainingTime]);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
      </Box>
    );
  }

  if (!location) {
    return (
      <Box textAlign="center" mt={5}>
        <Typography variant="h5">No location data available</Typography>
      </Box>
    );
  }

  const iconUrl = '/maintenance_man_icon.png'; // Custom icon for marker

  return (
    <LoadScriptNext
      googleMapsApiKey={apiKey}
      loadingElement={<CircularProgress />}
      onLoad={handleMapsLoaded} // Trigger when Maps API is fully loaded
    >
      {mapsLoaded ? (
        <GoogleMap
          mapContainerStyle={{ width: '100%', height: '400px' }}
          center={{ lat: location.lat, lng: location.lng }}
          zoom={12}
        >
          <Marker
            position={{ lat: location.lat, lng: location.lng }}
            icon={{
              url: iconUrl,
              scaledSize: new window.google.maps.Size(40, 40),
              anchor: new window.google.maps.Point(20, 20),
            }}
          />
          {directionsResponse && <DirectionsRenderer directions={directionsResponse} />}
        </GoogleMap>
      ) : (
        <CircularProgress />
      )}
      
      <Box mt={2} textAlign="center">
        {location.lastUpdated && (
          <Typography variant="h6">
            Last updated: {new Date(location.lastUpdated.toDate()).toLocaleString()}
          </Typography>
        )}
        <Typography variant="h6" color={isTrackingActive ? "green" : "red"}>
          Tracking Status: {isTrackingActive ? "Active" : "Inactive"}
        </Typography>

        {isTrackingActive && remainingTime && (
          <Typography variant="h6">
            Time Remaining: {Math.floor(remainingTime / 60000)}:{Math.floor((remainingTime % 60000) / 1000).toString().padStart(2, '0')}
          </Typography>
        )}
      </Box>
    </LoadScriptNext>
  );
}

export default TrackLocation;
