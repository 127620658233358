import React from 'react';
import { Container, Typography, Box } from '@mui/material';
import { useLocation } from 'react-router-dom'; // You'll need react-router for navigation

function PaymentConfirmation() {
    const location = useLocation();
    const { rentAmount, paymentReference } = location.state || {}; // Access the data passed from the PayRent page

    return (
        <Container maxWidth="sm">
            <Box 
                display="flex" 
                flexDirection="column" 
                alignItems="center" 
                justifyContent="flex-start" 
                sx={{ paddingTop: '50px', paddingBottom: '50px' }}
            >
                <Typography variant="h4" gutterBottom>
                    Payment Initiated
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Thank you, your payment has been successfully initiated.
                </Typography>
                <Typography variant="body1" gutterBottom>
                    You should be redirected to your bank’s website now to complete the payment of €{rentAmount}.
                </Typography>
                <Typography variant="body2" color="textSecondary" gutterBottom>
                    If you encounter any issues with your payment, please provide us with this reference: <strong>{paymentReference}</strong>
                </Typography>
            </Box>
        </Container>
    );
}

export default PaymentConfirmation;
